import React, { useState, useEffect } from "react";
import CustomerInfoModal from "../../shared/CustomerInfoModal";
import moment from "moment";
import { dynamoDbClient } from "../../aws";
import { connect } from "react-redux";
import _ from "lodash";
import { blockScreen, unBlockScreen } from "../../utils/blockScreen";

function CustomerInfo(props) {
  const [selectedData, setSelectedData] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [lastEvaluatedKeyStack, setLastEvaluatedKeyStack] = useState([]);
  const [hasNextPage, setHasNextPage] = useState(true);

  useEffect(() => {
     fetchCustomer(null);
  }, []);

  async function fetchCustomer(lastEvaluatedKey) {
    let params = {
      TableName: "V1_Tax_Customer_Address", 
      KeyConditionExpression: "customerId = :customerId",
      ExpressionAttributeValues: {
        ":customerId": props.customerId,
      },
      ScanIndexForward: false,
      Limit: 100,
    };

    if (lastEvaluatedKey) {
      params.ExclusiveStartKey = lastEvaluatedKey;
    }

    blockScreen()
    const data = await dynamoDbClient.query(params).promise()
        setCustomers(data.Items);
        setHasNextPage(!!data.LastEvaluatedKey);
  
        if (data.LastEvaluatedKey) {
          setLastEvaluatedKeyStack(stack => [...stack, data.LastEvaluatedKey]);  
        } else { 
          setLastEvaluatedKeyStack(stack => lastEvaluatedKey ? [...stack] : []);
        }
        unBlockScreen()
      
  }

  function deleteCustomer(customer) {
    let res = window.confirm(`คุณแน่ใจว่าต้องการลบ "${customer.name}"`);

    if (!res) return;

    var params = {
      TableName: "V1_Tax_Customer_Address",
      Key: {
        customerId: customer.customerId,
        taxId: customer.taxId,
      },
    };
    dynamoDbClient.delete(params, (err, data) => {
      if (err) {
        console.log(err);
      } else {
        alert(`ลบ ${customer.name} สำเร็จ`);
        fetchCustomer(null);
      }
    });
  }

  async function fetchNextPage() {
    const currentLastEvaluatedKey =
      lastEvaluatedKeyStack[lastEvaluatedKeyStack.length - 1] || null;
    await fetchCustomer(currentLastEvaluatedKey);
    setCurrentPage(currentPage+1)
  }
  async function firstPage(){
    await fetchCustomer(null)
    setCurrentPage(0)
  }



  return (
    <div>
      <CustomerInfoModal
        selected={selectedData}
        setSelectedData={setSelectedData}
        fetchCustomer={fetchCustomer}
      />

      <div class="card card-table">
        <div class="card-header">
       
          <div class="row">    
          <div class="col">ข้อมูลลูกค้า </div>    
          <div class="col-4 text-right">
          <div style={{ display: "inline" }}> 
            <span>{currentPage * 100 + 1} - {(currentPage + 1) * 100}</span>         
            <button
                class="ml-3 btn btn-space btn-secondary btn-lg"
                onClick={firstPage}
                disabled={currentPage === 0}
              >
                {"<<"} Begin
              </button>
           
              <button
                class="btn btn-space btn-secondary btn-lg"
                onClick={fetchNextPage}
                disabled={!hasNextPage}
              >
                Next page <i class="icon icon-left mdi mdi-arrow-right" />
              </button>
            </div>

            <button
              class="btn btn-space btn-primary btn-lg"
              data-toggle="modal"
              data-target="#customerInfoModal"
              onClick={() => setSelectedData(null)}
            >
              <span class="icon mdi mdi-plus" style={{ color: "white" }} />
              Create
            </button>
            </div>    
           
           
          </div>
        </div>
        <div class="card-body">
          <table class="table table-striped table-borderless">
            <thead>
              <tr>
                <th>Tax Id</th>
                <th>Name</th>
                <th>Branch Id</th>
                <th>Address</th>
                <th class="actions" />
              </tr>
            </thead>
            <tbody class="no-border-x">
              {_.map(customers, (customer) => {
                return (
                  <tr>
                    <td>{customer.taxId}</td>
                    <td>{customer.name}</td>
                    <td>{customer.branchId}</td>
                    <td>{customer.address}</td>
                    <td class="actions">
                      <a
                        class="icon pr-2"
                        href="#"
                        style={{ color: "orange" }}
                        data-toggle="modal"
                        data-target="#customerInfoModal"
                        onClick={(e) => {
                          e.preventDefault();
                          setSelectedData(customer);
                        }}
                      >
                        <i class="mdi mdi-edit" />
                      </a>
                      <a
                        class="icon"
                        href="#"
                        style={{ color: "red" }}
                        onClick={(e) => {
                          e.preventDefault();
                          deleteCustomer(customer);
                        }}
                      >
                        <i class="mdi mdi-delete" />
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    customerId: state.user.customerId,
  };
};

export default connect(mapStateToProps)(CustomerInfo);
