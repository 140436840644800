import AWS, { S3 } from "aws-sdk";
 

const KEYS = "AKIAY4QLEBA7QGTFQP5IABCDEFGuySQ/D3UHy45+6Igl3gErldYhuwybF2yJ6y4zB+r";

export const TABLE_RECEIPT_IMAGE = "ReceiptImage";
export const TABLE_V1_RECEIPTS_FULL_VAT = "V1-Receipts-Full-Vat";
export const TABLE_ACCOUNTING_SHOP = "V1-Accounting-Shop";


const ACCESS_KEY = KEYS.split("ABCDEFG")[0];
const SECRET_KEY =  KEYS.split("ABCDEFG")[1];

export const dynamoDbClient = new AWS.DynamoDB.DocumentClient({
  apiVersion: "2012-08-10",
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_KEY,
  region :  'ap-southeast-1',// 'https://dynamodb.ap-southeast-1.console.aws.amazon.com'
});


export const s3 = new AWS.S3({
  apiVersion: '2006-03-01',
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_KEY,
  region :  'ap-southeast-1',// 'https://dynamodb.ap-southeast-1.console.aws.amazon.com'

});