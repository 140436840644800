import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
const active = {
  borderBottom: "3px solid #4285f4"
};

const Nav = ({
  shopName = "SHOP_NAME",
  handleLogout,
  username = "USERNAME",
  tab,
  setTab
}) => {
  return (
    <nav className="navbar navbar-expand fixed-top be-top-header">
      <div className="container-fluid">
        {/* RIGHT */}
        <div className="be-right-navbar">
          <span
            style={{
              lineHeight: "60px",
              fontSize: 16,
              fontWeight: 300,
              marginRight: 12
            }}
            className="user-name"
          >
            {username}
          </span>

          <ul className="nav navbar-nav float-right be-user-nav">
            {/* <li className="nav-item dropdown">
         
          <span className="user-name">{username}</span>
          </li> */}
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                data-toggle="dropdown"
                role="button"
                aria-expanded="false"
              >
                {/* <img src="assets/img/avatar.png" alt="Avatar" /> */}
                <span className="icon mdi mdi-power" />
              </a>
              <div className="dropdown-menu" role="menu">
                <a className="dropdown-item" onClick={handleLogout}>
                  <span className="icon mdi mdi-power" />
                  Logout
                </a>
              </div>
            </li>
          </ul>
        </div>
        <a
          className="be-toggle-top-header-menu collapsed"
          href="#"
          id="sub-navigation"
          data-toggle="collapse"
          aria-expanded="false"
          data-target="#be-navbar-collapse"
        >
          No Sidebar Left
        </a>

        {/* CENTER */}
        <div className="navbar-collapse collapse" id="be-navbar-collapse">
          <span style={{ fontSize: 24, marginRight: 24, marginLeft: 24 }}>
            {shopName}
          </span>

          <ul className="navbar-nav">
            <li className="nav-item">
              <Link
                style={tab === 0 ? active : {}}
                className="nav-link"
                to="/"
                onClick={() => setTab(0)}
              >
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                style={tab === 1 ? active : {}}
                className="nav-link"
                to="/Receipts"
                onClick={() => setTab(1)}
              >
                Receipts
              </Link>
            </li>
            <li className="nav-item">
              <Link
                style={tab === 2 ? active : {}}
                className="nav-link"
                to="/FullTaxInvoice"
                onClick={() => setTab(2)}
              >
                ใบกำกับภาษีเต็มรูปแบบ
              </Link>
            </li>
            <li className="nav-item">
              <Link
                style={tab === 3 ? active : {}}
                className="nav-link"
                to="/CustomerInfo"
                onClick={() => setTab(3)}
              >
                ข้อมูลลูกค้า
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    tab: state.view.tab || 0
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setTab: index => {
      dispatch({
        type: "VIEW",
        payload: index,
        key: "tab"
      });
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Nav);
