

import React from 'react'

 

export default   ({ text, handleOnClick, children }) => {
    return (
      <button
        class="btn btn-rounded btn-space btn-primary"
        type="button"
        data-toggle="tooltip"
        data-placement="bottom"
        title=""
        style={{ marginBottom: 0 }}
        data-original-title="Tooltip on bottom"
        onClick={handleOnClick}
      >
        {children}
      </button>
    );


  };
  