import React, { Component } from "react";
import { TABLE_V1_RECEIPTS_FULL_VAT } from "../../aws";
import { dynamoDbClient, TABLE_RECEIPT_IMAGE } from "../../aws";
import { withRouter } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import { connect } from "react-redux";
import ReactTooltip, { show } from "react-tooltip";
import ServiceFullVat from "../../service/fullVatService";
import {
  VIEW,
  HOLDER,
  downloadExportExcel,
  downloadTaxInvoiceReportExcel
} from "../../redux/actions";
import { blockScreen, unBlockScreen } from "../../utils/blockScreen";
import downloadFileFromUrl from "../../utils/downloadFileFromUrl";
import { branchFormatForWeb, twoDecimal } from "../../utils/format";
import { computeVat, computeVat_V2, displayCurrency, vatGenerate } from "../../utils";
import PrintThermal from "./printthermal";
import PrintReceipt from "../../printer/recieptsPrint";
let _forceQueryYYYYMM = null;

const getLastDate = () => {
  return _forceQueryYYYYMM;
};
class FullTaxReceipts extends Component {
  state = {
    list: [],
    indexPage: 0,
    loading: false,
    LastEvaluatedKey: null,
    query: getLastDate() || moment().format("YYYY-MM"), // null //ex. 'YYYY-MM', 'YYYY-MM-DD',
    monthList: _.times(12, t => {
      return {
        display: moment()
          .add(-t, "month")
          .format("YYYY-MMMM"),
        value: moment()
          .add(-t, "month")
          .format("YYYY-MM")
      };
    })
  };

  componentDidMount() {
    this.fetch();
  }

  setQuery = forceQueryYYYYMM => {
    _forceQueryYYYYMM = forceQueryYYYYMM;
    this.setState({
      query: forceQueryYYYYMM,
      LastEvaluatedKey: null,
      indexPage: 0
    });
    setTimeout(this.fetch, 50);
  };

  fetch = LastEvaluatedKey => {
    this.setState({ list: [] });

    var date = getLastDate() || this.state.query;
    var params = {
      TableName: TABLE_V1_RECEIPTS_FULL_VAT,
      IndexName: "customerId-fullTaxId-index",
      KeyConditionExpression:
        "customerId = :hkey and         begins_with (#rkey , :rkey)  ",

      ExpressionAttributeValues: {
        ":hkey": this.props.user.customerId,
        ":rkey": date // forceQueryYYYYMM ||  this.state.query,
      },
      ExpressionAttributeNames: {
        "#rkey": "fullTaxId"
      },
      ScanIndexForward: false,
      Limit: 100,
      ReturnConsumedCapacity: "TOTAL"
    };

    if (LastEvaluatedKey) {
      params.ExclusiveStartKey = LastEvaluatedKey;
    }

    var self = this;
    this.setState({ loading: true });
    dynamoDbClient.query(params, (err, data) => {
      if (err) {
        alert(err);
        return console.log(err);
      }

      self.setState({
        loading: false,
        indexPage: LastEvaluatedKey ? self.state.indexPage + 1 : 0,
        LastEvaluatedKey: data.LastEvaluatedKey || null,
        list: _.sortBy(data.Items, line => {
          var lastString = line.fullTaxId
            ? line.fullTaxId.split("-")
            : line.fullInvoiceId.split("-");
          return -lastString[2];
        })
      });
    });
  };

  changePage = value => {
    if (this.state.loading) {
      return console.log("Loading...");
    }
    if (value >= 1 && this.state.LastEvaluatedKey == null) {
      return alert("No more data");
    }
    value >= 1
      ? this.fetch(this.state.LastEvaluatedKey)
      : this.handleMonthChange(this.state.query);
  };

  handleMonthChange = valueBusinessDate => {
    this.setQuery(valueBusinessDate);
  };

  openFullView = receipt => {
    this.props.dispatch({
      type: HOLDER,
      key: "receipt",
      payload: receipt
    });

    this.props.dispatch({
      type: VIEW,
      key: "receipt",
      payload: 1
    });

    this.props.history.push("/Detail#");
  };

  editInvoiceBill = index => {
    this._receiptConvert.current.open(this.state.list[index]);
  };

  handleDownload = (receipt, mode) => {
    console.log('================receipt=============');
    console.log(receipt);
    console.log('=============receipt===================');
     
    var service = new ServiceFullVat();
    service.download(receipt, this.props.shopInfo, mode);
  };

  handleExportExcel = mode => {
    var params = {
      customerId: this.props.user.customerId,
      query: this.state.query,
      company: this.props.shopInfo
    };

    blockScreen();
    this.props.dispatch(
      downloadExportExcel(params, {}, urlLink => {
        downloadFileFromUrl(urlLink);
        unBlockScreen();
      })
    );
  };

  exportTaxInvoiceReportExcel = mode => {
    let self = this;
    var params = {
      customerId: self.props.user.customerId,
      query: self.state.query,
      company: self.props.shopInfo
    };

    blockScreen();
    this.props.dispatch(
      downloadTaxInvoiceReportExcel(params, {}, urlLink => {
        if (urlLink) {
          downloadFileFromUrl(urlLink);
        } else {
          alert("Failed");
        }
        unBlockScreen();
      })
    );
  };

  handleExportDownloadAllPdf = mode => {
    var service = new ServiceFullVat();

    let receipts_Good = _.filter(this.state.list, l => !l.void )
    service.download(receipts_Good, this.props.shopInfo, mode);
  };
  printthermal = (receipt, mode) => {
    let data = {};
    let print = new PrintThermal(this.props.dispatch, receipt, mode);
    print.build().print();

    // let data = {};
    // let print = new PrintReceipt(this.props.dispatch, receipt);
    // print.build().print();
  };

  render() {
    let _filterList = null;
    let { filterDate } = this.props;

    let isNewData = this.props.match.params.isNewData;
    const hidePickMonth = this.props.hidePickMonth === true ? true : false;
    if (filterDate) {
      _filterList = _.filter(
        this.state.list,
        r => r.Date.slice(0, 10) == filterDate
      );
    } else {
      _filterList = this.state.list;
    }
    return (
      <div class="row">
        <div className="col-lg-12">
          <div className="card card-table">
            <div className="card-header">
              ออกใบกำกับภาษีเต็มรูปแบบ
              <div class="row">
                <div class="col-md-4">
                  {hidePickMonth !== true && (
                    <select
                      value={this.state.query}
                      class="form-control"
                      onChange={e => this.handleMonthChange(e.target.value)}
                    >
                      {_.map(this.state.monthList, obj => {
                        return <option value={obj.value}>{obj.display}</option>;
                      })}
                    </select>
                  )}
                </div>

                <div class="col-md-8 text-right">
                  {hidePickMonth !== true && [
                    <div style={{ display: "inline" }}>
                      {this.state.indexPage * 100 + 1} -{" "}
                      {(this.state.indexPage + 1) * 100}
                      <button
                        class="ml-3 btn btn-space btn-secondary btn-lg"
                        onClick={() => this.changePage(-1)}
                      >
                        <i class="icon icon-left mdi mdi-arrow-left" />
                        Begin
                      </button>
                      <button
                        class="btn btn-space btn-secondary btn-lg"
                        onClick={() => this.changePage(1)}
                      >
                        Next page{" "}
                        <i class="icon icon-left mdi mdi-arrow-right" />
                      </button>
                    </div>,
                    <div class="btn-group btn-space " style={{ paddingTop: 4 }}>
                      <button
                        class="btn btn-primary  btn-lg  btn-space dropdown-toggle"
                        type="button"
                        data-toggle="dropdown"
                        aria-expanded="true"
                      >
                        <i class="icon icon-left mdi mdi-collection-pdf" />
                        Download All PDF (This page){" "}
                        <span class="icon-dropdown mdi mdi-chevron-down" />
                      </button>
                      <div
                        class="dropdown-menu "
                        role="menu"
                        x-placement="top-start"
                      >
                        <a
                          class="dropdown-item"
                          href="#"
                          onClick={() => this.handleExportDownloadAllPdf(0)}
                        >
                          ต้นฉบับ
                        </a>
                        <a
                          class="dropdown-item"
                          href="#"
                          onClick={() => this.handleExportDownloadAllPdf(1)}
                        >
                          สำเนา
                        </a>

                        <div class="dropdown-divider" />
                        <a
                          class="dropdown-item"
                          href="#"
                          onClick={() => this.handleExportDownloadAllPdf(2)}
                        >
                          ต้นฉบับ + สำเนา
                        </a>
                      </div>
                    </div>,
                    <div class="btn-group btn-space " style={{ paddingTop: 4 }}>
                      <button
                        class="btn btn-primary  btn-lg  btn-space dropdown-toggle"
                        type="button"
                        data-toggle="dropdown"
                        aria-expanded="true"
                      >
                        {/* <i class="icon icon-left mdi mdi-collection-pdf" /> */}
                        รายงานภาษีขาย
                        <span class="icon-dropdown mdi mdi-chevron-down" />
                      </button>
                      <div
                        class="dropdown-menu "
                        role="menu"
                        x-placement="top-start"
                      >
                        <a
                          class="dropdown-item"
                          href="#"
                          onClick={this.handleExportExcel}
                        >
                          เต็มรูปแบบ
                        </a>
                        <a
                          class="dropdown-item"
                          href="#"
                          onClick={this.exportTaxInvoiceReportExcel}
                        >
                          ใบกำกับภาษีแบบย่อ + เต็มรูปแบบ
                        </a>
                      </div>
                    </div>
                  ]}
                </div>
              </div>
            </div>
            <div className="card-body">
              <table className="table table-sm table-condensed table-striped table-borderless">
                <thead>
                  <tr>
                    <th>ใบกำกับภาษีเลขที่</th>
                    <th>วันที่ออก</th>
                    <th>ลูกค้า</th>
                    <th>TaxId</th>

                    <th className="number">Before</th>
                    <th className="number">Vat</th>
                    <th className="number">Rounding</th>
                   
                    <th className="number">Grandtotal</th>
                    <th className="number">เอกสารเลขที่</th>
                    <th>วันที่ชำระ</th>
                    <th> Actions </th>
                  </tr>
                </thead>
                <tbody className="no-border-x">
                  {_.map(
                    _filterList,
                    (
                      line,
                      i
                    ) => {
                      let {
                        issue = "issue",
                        Vat,
                        customer,
                        Date,
                        fullTaxId,
                        ReceiptId,
                        Table,
                        GrandTotal,
                        VatMode,
                        VatValue,
                        SubTotal,
                        ItemDiscount,
                        ServiceCharge,
                        Discount,
                       
                      } = line
                      
                      let { beforeVat, vat, rounding, grandTotal  } =  computeVat_V2(line)

                      
                      if(line.void){
                        return ( 
                          <tr
                          className={
                            isNewData && i === 0 ? "table-success" : ""
                          }
                        >
                          <td>{fullTaxId}</td>
                          <td>{issue}</td>
                          <td
                            //  data-for="company_info"
                            data-tip={JSON.stringify(customer)}
                          >
                            <span> {subStringText(customer.name)}</span>
                            <ReactTooltip
                              place="right"
                              effect="solid"
                              //  id="company_info"
                              getContent={(data = {}) => {
                                var data1 = JSON.parse(data);
                                return [
                                  <p>ชื่อ : {data1 && data1.name}</p>,
                                  <p>
                                    เลขผู้เสียภาษี : {data1 && data1.taxId}
                                  </p>,

                                  <p>
                                    สาขา :{" "}
                                    {data1 &&
                                      data1.branchId &&
                                      branchFormatForWeb(data1.branchId)}
                                  </p>,

                                  <p>ที่อยู่ : {data1 && data1.address}</p>
                                ];
                              }}
                            />
                          </td>

                          <td>{customer && customer.taxId}</td>

                          <td className="number">
                            
                          </td>

                          <td className="number">
                            
                          </td>
                      
                          <td className="number">
                            <strong>VOID</strong>{" "}
                          </td>

                          <td className="number">#{ReceiptId}</td>
                          <td>
                            <span style={{}}>
                              {moment(Date).format("DD/MM/YYYY")}
                            </span>
                            <span style={{ marginLeft: 8, color: "gray" }}>
                              {moment(Date).format("HH:mm:ss")}
                            </span>
                          </td>
                          <td>
                          </td>
                        </tr>
                     
                        )
                      }

                      return (
                        <tr
                          className={
                            isNewData && i === 0 ? "table-success" : ""
                          }
                        >
                          <td>{fullTaxId}</td>
                          <td>{issue}</td>
                          <td
                            //  data-for="company_info"
                            data-tip={JSON.stringify(customer)}
                          >
                            <span> {subStringText(customer.name)}</span>
                            <ReactTooltip
                              place="right"
                              effect="solid"
                              //  id="company_info"
                              getContent={(data = {}) => {
                                var data1 = JSON.parse(data);
                                return [
                                  <p>ชื่อ : {data1 && data1.name}</p>,
                                  <p>
                                    เลขผู้เสียภาษี : {data1 && data1.taxId}
                                  </p>,

                                  <p>
                                    สาขา :{" "}
                                    {data1 &&
                                      data1.branchId &&
                                      branchFormatForWeb(data1.branchId)}
                                  </p>,

                                  <p>ที่อยู่ : {data1 && data1.address}</p>
                                ];
                              }}
                            />
                          </td>

                          <td>{customer && customer.taxId}</td>

                          <td className="number">
                            {displayCurrency(beforeVat) }
                          </td>

                          <td className="number">
                            {displayCurrency(vat) }
                          </td>   
                          
                          <td className="number">
                            {displayCurrency(rounding) }
                          </td>
                      
                          <td className="number">
                            <strong>{displayCurrency(grandTotal)}</strong>{" "}
                          </td>

                          <td className="number">#{ReceiptId}</td>
                          <td>
                            <span style={{}}>
                              {moment(Date).format("DD/MM/YYYY")}
                            </span>
                            <span style={{ marginLeft: 8, color: "gray" }}>
                              {moment(Date).format("HH:mm:ss")}
                            </span>
                          </td>
                          <td>
                            {/* <EnhanceButton>ViewRRR</EnhanceButton>
                          <EnhanceButton>EditRRR</EnhanceButton>
                          <EnhanceButton>RemoveRRR</EnhanceButton> */}
                            <button
                              onClick={() => this.openFullView(_filterList[i])}
                              class="btn btn-rounded btn-space btn-secondary"
                            >
                              View
                            </button>

                            <div class="btn-group btn-space ">
                              <button
                                class="btn btn-secondary   btn-rounded btn-space dropdown-toggle"
                                type="button"
                                data-toggle="dropdown"
                                aria-expanded="true"
                                style={{ marginTop: 2 }}
                              >
                                Exports
                                <span class="icon-dropdown mdi mdi-chevron-down" />
                              </button>
                              <div
                                class="dropdown-menu "
                                role="menu"
                                x-placement="top-start"
                              >
                                <a
                                  onClick={() =>
                                    this.handleDownload(_filterList[i], 0)
                                  }
                                  class="dropdown-item"
                                  href="#"
                                >
                                  PDF ต้นฉบับ
                                </a>
                                <a
                                  onClick={() =>
                                    this.handleDownload(_filterList[i], 1)
                                  }
                                  class="dropdown-item"
                                  href="#"
                                >
                                  PDF สำเนา
                                </a>
                                <a
                                  onClick={() =>
                                    this.printthermal(_filterList[i], "ต้นฉบับ")
                                  }
                                  class="dropdown-item"
                                  href="#"
                                >
                                  Print thermal(ต้นฉบับ)
                                </a>
                                <a
                                  onClick={() =>
                                    this.printthermal(_filterList[i], "สำเนา")
                                  }
                                  class="dropdown-item"
                                  href="#"
                                >
                                  Print thermal(สำเนา)
                                </a>

                                <div class="dropdown-divider" />
                                <a
                                  onClick={() =>
                                    this.handleDownload(_filterList[i], 2)
                                  }
                                  class="dropdown-item"
                                  href="#"
                                >
                                  PDF ต้นฉบับ + สำเนา
                                </a>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
    shopInfo: state.shopInfo
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FullTaxReceipts));

function subStringText(text = "") {
  if (text.length > 50) {
    return text.match(new RegExp(".{1," + 47 + "}", "g"))[0] + "...";
  }
  return text;
}
