import { USER , SHOP_INFO, VIEW} from './actions'

let init = {
    username : null,
    customerId : null,
    shopName : null,
    businessName : 1 ,
}

export default (state = init, action) => {
    switch (action.type) {
        case USER:
                return { ...state, ...action.payload }
        default:
            return state;
    }
};