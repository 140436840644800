import _ from "lodash";
import React, { Component, createRef } from "react";
import Row from "./Row";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { blockScreen, unBlockScreen } from "../../utils/blockScreen";
import { dynamoDbClient, TABLE_RECEIPT_IMAGE } from "../../aws";
import { VIEW, HOLDER, downloadPdfReceiptsList } from "../../redux/actions";
import { connect } from "react-redux";
import FullVatService from "../../component/FullTaxReceipts.js";
import downloadLink from "../../utils/downloadFileFromUrl";
import CompactFullTaxReceipts from '../../component/CompactFullTaxReceipts'

class index extends Component {
  state = {
    businessDate: moment()
      .add(-7, "h")
      .format("YYYY-MM-DD"),
    displayDate: moment()
      .add(-7, "h")
      .format("DD/MM/YYYY"),
    dataList: [], //[2, 3, 45, 435, 34, 54, 36, 5],
    dataConverted: [],
    time: moment().format(),
    customerId: this.props.user.customerId,
    fullVatRefInstance :  createRef(),

    tmp_receipts : []
  };

  firstLoad = true;

  fetch = (lastKey) => {
     
    
    this.setState({ dataList: [] });

    var date = this.state.businessDate;
    var params = {
      TableName: TABLE_RECEIPT_IMAGE,
      KeyConditionExpression:
        //"customerId = :hkey and begins_with (#rkey, :rkey)",
        "customerId = :hkey and         #rkey BETWEEN :rkey1 AND :rkey2 ",

      ExpressionAttributeValues: {
        ":hkey": this.state.customerId.toString(),
        ":rkey1": moment(date)
          .add(0, "d")
          .startOf("day")
          .add(7, "h")
          .format("YYYY-MM-DDTHH"),
        ":rkey2": moment(date)
          .add(1, "d")
          .startOf("day")
          .add(7, "h")
          .format("YYYY-MM-DDTHH")
      },
      ExpressionAttributeNames: {
        "#rkey": "datetime-table-grandtotal"
      },
      ReturnConsumedCapacity: "TOTAL"
    };
    if( lastKey  && lastKey.customerId ) { 
      params.ExclusiveStartKey = lastKey
    }
    var self = this;
    blockScreen();
    dynamoDbClient.query(params, (err, data) => {
      if (err) {
        console.log(err);
      }
      self.setState( {
        tmp_receipts : [...self.state.tmp_receipts, ...data.Items]
      });

      if(data.LastEvaluatedKey) { 
        self.fetch(data.LastEvaluatedKey);
        return 
      }
      
      var res = _.filter(self.state.tmp_receipts, line => {
        return (line  && ( !line.void &&   !line.isVoid )) ; //!line.converted;
      });



      res = res.map(line => {
        let n = { ...line.image, ...line };
        delete n.image;
        return n;
      });
      

      res = _.sortBy(res, l => l.Date);
      res = _.reverse(res);
       
      self.setState({
        dataList: _.filter(res, r => !r.converted),
        dataConverted: _.filter(data.Items, line => {
          return line.converted;
        }),
        sumGrandTotal: _.sumBy(res, "GrandTotal"),
        sumVat: _.sumBy(res, "Vat"),
        tmp_receipts : []
        
      });
       

      setTimeout(() => {
        unBlockScreen();
      }, 400);
    });
     ;
    if(this.firstLoad == false){
      var instance = this.state.fullVatRefInstance
    }
    this.firstLoad = false
  };

  downloadInvoicesResult = () => {
    blockScreen();
    var params = {
      customerId: this.props.user.customerId,
      query: moment(this.state.businessDate).format("YYYY-MM"),
      company: this.props.shopInfo
    };

    var options = {
      title : " Summary Invoices (ABB)"
    } 
    this.props.dispatch(
      downloadPdfReceiptsList(params, options , urlLink => {
        downloadLink(urlLink);
        unBlockScreen();
      })
    );
  };

  componentDidMount() {
    this.fetch();
    var self = this;
    window.$('input[name="businessDate"]').daterangepicker(
      {
        singleDatePicker: true,
        // showDropdowns: true,
        minYear: 2018,
        minDate: "01/12/2018",
        // maxYear: parseInt(moment().format("YYYY"), 10),
        startDate: this.state.displayDate, //moment().format('DD/MM/YYYY'),
        locale: {
          format: "DD/MM/YYYY" //   <===**
        }
      },
      function(start, end, label) {
        self.setState({
          businessDate: start.format("YYYY-MM-DD"),
          displayDate: start.format("DD/MM/YYYY")
        });
        self.fetch();
        // var years = moment().diff(start, "years");
        // alert("You are " + years + " years old!");
      }
    );
  }

  openModal = () => {
    //this.myRef.open()
    this.setState({ loading: true, time: moment().format() });
    this.state.dataList;
    this.myRef.current.open();
  };

  openViewReceiptModal = receiptLine => {
    this.props.dispatch({
      type: HOLDER,
      key: "receipt",
      payload: receiptLine
    });

    this.props.dispatch({
      type: VIEW,
      key: "receipt",
      payload: 0
    });

    this.props.history.push("/Detail#");
  };

  openReceiptConvertModal = index => {
    var self = this;
    this._receiptConvert.current.open(this.state.dataList[index], () => {
      self.fetch();
    });
  };

  render() {
    return (
      <div className="row">
        <div class="col-lg-12  " style={{ marginBottom: 25 }}>
          <span style={{ color: "blue", fontSize: 18 }}>เลือกวันที่</span>
          <input
            style={{
              marginLeft: 8,
              display: "inline-block",
              width: 170,
              fontSize: 16,
              lineHeight: "36px",
              paddingLeft: 24
            }}
            type="text"
            name="businessDate"
            class="form-control"
            value={this.state.displayDate}
            autocomplete="off"
          />

          <button
            style={{ float: "right" }}
            class="btn btn-space btn-primary btn-lg"
            onClick={this.fetch}
          >
            <i class="icon icon-left mdi mdi-refresh-alt" />
            Reload
          </button>
  

          <button
            style={{ float: "right" }}
            class="btn btn-space btn-primary btn-lg"
            onClick={this.downloadInvoicesResult}
          >
            <i class="icon icon-left mdi mdi-archive" />
            Summary Invoices (ABB)
          </button>

          
          {/* <button class="btn btn-space btn-primary btn-lg" onClick={this.fetch}>
            <i class="icon icon-left mdi mdi-refresh-alt" />Print 
          </button> */}
        </div>
       

         {/* Full Tax Invoice */}
         <div className="col-lg-12">
          <CompactFullTaxReceipts
            query={this.state.businessDate}
            
          />
        </div>

        {/* ABB invoice */}
        <div className="col-lg-12">
          <div className="card card-table">
            <div className="card-header">
              Grand Total : {this.state.sumGrandTotal}
              <span style={{ color: "gray", marginLeft: 8 }}>
                ({this.state.dataList.length} bills)
              </span>
              {/* <input
                style={{ marginLeft: 8 }}
                type="text"
                name="businessDate"
                value={this.state.displayDate}
              /> */}
              <div className="tools dropdown">
                <button
                  style={{ float: "right" }}
                  class="btn btn-space btn-primary btn-lg"
                  onClick={this.fetch}
                >
                  <i
                    style={{ color: "white" }}
                    class="icon icon-left mdi mdi-refresh-alt"
                  />
                  Reload
                </button>
              </div>
            </div>
            <div className="card-body">
              <table className="table table-sm table-condensed table-striped table-borderless">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Date</th>
                    <th>Invoice Id</th>
                    <th> Table </th>
                    <th className="number">Before</th>
                    <th className="number">Vat</th>
                    <th className="number">Rounding</th>
                    <th className="number">GrandTotal</th>
                 
                    <th className="actions">Actions</th>
                  </tr>
                </thead>
                <tbody className="no-border-x">
                  {_.map(this.state.dataList, (line, i) => (
                    <Row
                      receipt={line} 
                      i={i}
                      key={i}
                      onClickLine={this.openViewReceiptModal}
                      onClickConvertLine={this.openReceiptConvertModal}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
    shopInfo: state.shopInfo
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(index));
