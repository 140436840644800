import React, { Component, useState, useEffect } from "react";
import { TABLE_V1_RECEIPTS_FULL_VAT } from "../aws";
import { dynamoDbClient, TABLE_RECEIPT_IMAGE } from "../aws";
import { withRouter } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import ServiceFullVat from "../service/fullVatService";
import { VIEW, HOLDER, downloadExportExcel } from "../redux/actions";
import { blockScreen, unBlockScreen } from "../utils/blockScreen";
import downloadFileFromUrl from "../utils/downloadFileFromUrl";
import { branchFormatForWeb, twoDecimal } from "../utils/format";
import { computeVat, computeVat_V2, displayCurrency, vatGenerate } from "../utils";

function CompactFullTaxReceipts({
  query,
  user,
  shopInfo,
  match,
  history,
  dispatch
}) {
  const [list, setList] = useState([]);
  const [indexPage, setIndexPage] = useState(0);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);

  useEffect(() => {
    if (query) {
      fetch();
    }
  }, [query]);

  const fetch = _.throttle(LastEvaluatedKey => {
    var date = query;
    var params = {
      TableName: TABLE_V1_RECEIPTS_FULL_VAT,
      KeyConditionExpression:
        "customerId = :hkey and         #rkey BETWEEN :rkey1 AND :rkey2 ",

      ExpressionAttributeValues: {
        ":hkey": user.customerId,
        ":rkey1": moment(date)
          .startOf("day")
          .add(7, "h")
          .format("YYYY-MM-DDTHH"),

        ":rkey2": moment(date)
          .endOf("day")
          .add(7, "h")
          .format("YYYY-MM-DDTHH")
      },
      ExpressionAttributeNames: {
        "#rkey": "datetime-table-grandtotal"
      },
      ScanIndexForward: false,
      Limit: 100,
      ReturnConsumedCapacity: "TOTAL"
    };

    if (LastEvaluatedKey) {
      params.ExclusiveStartKey = LastEvaluatedKey;
    }

    var self = this;

    dynamoDbClient.query(params, (err, data) => {
      if (err) {
        alert(err);
        return console.log(err);
      }
      setList(
        _.sortBy(data.Items, line => {
          var lastString = line.fullTaxId
            ? line.fullTaxId.split("-")
            : line.fullInvoiceId.split("-");
          return -lastString[2];
        })
      );
      setIndexPage(LastEvaluatedKey ? indexPage + 1 : 0);
      setLastEvaluatedKey(data.LastEvaluatedKey || null);
    });
  }, 100);

  const changePage = value => {
    if (value >= 1 && this.state.LastEvaluatedKey == null) {
      return alert("No more data");
    }
    value >= 1
      ? this.fetch(this.state.LastEvaluatedKey)
      : this.handleMonthChange(this.state.query);
  };

  const openFullView = receipt => {
    dispatch({
      type: HOLDER,
      key: "receipt",
      payload: receipt
    });

    dispatch({
      type: VIEW,
      key: "receipt",
      payload: 1
    });
    dispatch({
      type: "VIEW",
      payload: 2,
      key: "tab"
    });
    history.push("/Detail#");
  };

  const editInvoiceBill = index => {
    this._receiptConvert.current.open(list[index]);
  };

  const handleDownload = (receipt, mode) => {
     
    var service = new ServiceFullVat();
    service.download(receipt, shopInfo, mode);
  };

  const handleExportExcel = mode => {
    var params = {
      customerId: this.props.user.customerId,
      query: this.state.query,
      company: this.props.shopInfo
    };

    blockScreen();
    this.props.dispatch(
      downloadExportExcel(params, {}, urlLink => {
        downloadFileFromUrl(urlLink);
        unBlockScreen();
      })
    );
  };

  const handleExportDownloadAllPdf = mode => {
    var service = new ServiceFullVat();
    service.download(this.state.list, this.props.shopInfo, mode);
  };

  useEffect(() => {
    if (!query) {
      fetch();
    }
  }, [query]);

  let isNewData = match.params.isNewData;

  return (
    <div class="row">
      <div className="col-lg-12">
        <div className="card card-table">
          <div className="card-header">ออกใบกำกับภาษีเต็มรูปแบบ</div>
          <div className="card-body">
            <table className="table table-sm table-condensed table-striped table-borderless">
              <thead>
                <tr>
                  <th>ใบกำกับภาษีเลขที่</th>
                  <th>วันที่ออก</th>
                  <th>ลูกค้า</th>
                  <th>TaxId</th>

                  {/* <th className="number">GrandTotal</th>
                  <th className="number">Vatable</th>
                  <th className="number">Vat</th> */}
                  <th className="number">Before</th>
                  <th className="number">Vat</th>
                  <th className="number">Rounding</th>
                  <th className="number">GrandTotal</th>
                  <th className="number">เอกสารเลขที่</th>
                  <th>วันที่ชำระ</th>
                  <th> Actions </th>
                </tr>
              </thead>
              <tbody className="no-border-x">
                {_.map(
                  list,
                  (
                    line,
                    i
                  ) => {
                    let {
                      issue = "issue",
                      Vat,
                      VatMode,
                      VatValue,
                      customer,
                      Date,
                      fullTaxId,
                      ReceiptId,
                      Table,
                      GrandTotal,
                      SubTotal,
                      ItemDiscount,
                      ServiceCharge,
                      Discount
                    } = line
                    
                    let { beforeVat, vat, rounding, grandTotal  } =  computeVat_V2(line)


                    if (line.void) {

                      return (
                        <tr
                          className={isNewData && i === 0 ? "table-success" : ""}
                        >
                          <td>{fullTaxId}</td>
                          <td>{issue}</td>
                          <td
                            //  data-for="company_info"
                            data-tip={JSON.stringify(customer)}
                          >
                            <span>{customer.name}</span>
                            <ReactTooltip
                              place="right"
                              effect="solid"
                              //  id="company_info"
                              getContent={(data = {}) => {
                                var data1 = JSON.parse(data);
                                return [
                                  <p>ชื่อ : {data1 && data1.name}</p>,
                                  <p>เลขผู้เสียภาษี : {data1 && data1.taxId}</p>,

                                  <p>
                                    สาขา :{" "}
                                    {data1 &&
                                      data1.branchId &&
                                      branchFormatForWeb(data1.branchId)}
                                  </p>,

                                  <p>ที่อยู่ : {data1 && data1.address}</p>
                                ];
                              }}
                            />
                          </td>

                          <td>{customer && customer.taxId}</td>

                          <td className="number"></td>

                      
                          <td className="number"></td>
                          <td className="number">
                            <strong>VOID</strong>
                          </td>

                          <td className="number">#{ReceiptId}</td>
                          <td>
                            <span style={{}}>
                              {moment(Date).format("DD/MM/YYYY")}
                            </span>
                            <span style={{ marginLeft: 8, color: "gray" }}>
                              {moment(Date).format("HH:mm:ss")}
                            </span>
                          </td>
                          <td>
                          </td>
                        </tr>
                      );
                    }
                    return (
                      <tr
                        className={isNewData && i === 0 ? "table-success" : ""}
                      >
                        <td>{fullTaxId}</td>
                        <td>{issue}</td>
                        <td
                          //  data-for="company_info"
                          data-tip={JSON.stringify(customer)}
                        >
                          <span>{customer.name}</span>
                          <ReactTooltip
                            place="right"
                            effect="solid"
                            //  id="company_info"
                            getContent={(data = {}) => {
                              var data1 = JSON.parse(data);
                              return [
                                <p>ชื่อ : {data1 && data1.name}</p>,
                                <p>เลขผู้เสียภาษี : {data1 && data1.taxId}</p>,

                                <p>
                                  สาขา :{" "}
                                  {data1 &&
                                    data1.branchId &&
                                    branchFormatForWeb(data1.branchId)}
                                </p>,

                                <p>ที่อยู่ : {data1 && data1.address}</p>
                              ];
                            }}
                          />
                        </td>

                        <td>{customer && customer.taxId}</td>

                        <td className="number">{ displayCurrency (beforeVat)}</td>

                        <td className="number">{displayCurrency(vat)}</td>
                        <td className="number">{displayCurrency(rounding)}</td>
                       
                        <td className="number">
                          <strong>{displayCurrency(grandTotal) }</strong>
                        </td>

                        <td className="number">#{ReceiptId}</td>
                        <td>
                          <span style={{}}>
                            {moment(Date).format("DD/MM/YYYY")}
                          </span>
                          <span style={{ marginLeft: 8, color: "gray" }}>
                            {moment(Date).format("HH:mm:ss")}
                          </span>
                        </td>
                        <td>
                          {/* <EnhanceButton>ViewRRR</EnhanceButton>
                        <EnhanceButton>EditRRR</EnhanceButton>
                        <EnhanceButton>RemoveRRR</EnhanceButton> */}
                          <button
                            onClick={() => openFullView(list[i])}
                            class="btn btn-rounded btn-space btn-secondary"
                          >
                            View
                          </button>

                          <div class="btn-group btn-space ">
                            <button
                              class="btn btn-secondary   btn-rounded btn-space dropdown-toggle"
                              type="button"
                              data-toggle="dropdown"
                              aria-expanded="true"
                              style={{ marginTop: 2 }}
                            >
                              Download PDF
                              <span class="icon-dropdown mdi mdi-chevron-down" />
                            </button>
                            <div
                              class="dropdown-menu "
                              role="menu"
                              x-placement="top-start"
                            >
                              <a
                                onClick={() => handleDownload(list[i], 0)}
                                class="dropdown-item"
                                href="#"
                              >
                                ต้นฉบับ
                              </a>
                              <a
                                onClick={() => handleDownload(list[i], 1)}
                                class="dropdown-item"
                                href="#"
                              >
                                สำเนา
                              </a>

                              <div class="dropdown-divider" />
                              <a
                                onClick={() => handleDownload(list[i], 2)}
                                class="dropdown-item"
                                href="#"
                              >
                                ต้นฉบับ + สำเนา
                              </a>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );

}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
    shopInfo: state.shopInfo
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CompactFullTaxReceipts));
