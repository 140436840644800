import { dynamoDbClient, TABLE_V1_RECEIPTS_FULL_VAT } from "../aws";
import * as simpleStore from "../simpleStore";
import async from "async";
import moment from "moment";
import { zeroPad, getBusinessDate } from "../utils/format";
import axios from "axios";
import mock from "./mock";
import _ from "lodash";
import { blockScreen, unBlockScreen } from "../utils/blockScreen";

import { mapperToPdfList }  from '../utils/fullvat.pdf'

export const TABLE_NAME = "V1-Receipts-Full-Vat";
export const TABLE_NAME_ACCOUNT_SHOP = "V1-Accounting-Shop";
export const TABLE_NAME_RECEIPT_IMAGE = "ReceiptImage";
export const URL_PDF_DATA = "https://us-central1-renew-tax-v1-niceloop.cloudfunctions.net/receiptReport" //a4
// export const URL_PDF_DATA = "http://localhost:5001/v1-tax-webfront/us-central1/receiptReport" //a4

  // "https://us-central1-accountingreport-cec02.cloudfunctions.net/receiptReport";

export default class FullVatService {

   
  constructor(customerId, user) {
     this.customerId  = customerId
     this.user = user;
  }
 

  fetch(cb) {
    var params = {
      TableName: TABLE_NAME,
      KeyConditionExpression: "customerId = :hkey",
      ExpressionAttributeValues: {
        ":hkey": "2"
      },
      ScanIndexForward: false
    };

    dynamoDbClient.query(params, cb);
  }

  // Create New One
  convert(receipt, customer = {}, shopInfo = {}, cbAll) {
    var self = this;
    var YYYY_MM = moment(receipt.Date)
      .add(-7, "h")
      .format("YYYY-MM");

    async.waterfall(
      [
        //fetch current Number
        cb => {
          var params = {
            TableName: TABLE_NAME_ACCOUNT_SHOP,
            Key: {
              customerId: this.customerId,  
            }
          };

          dynamoDbClient.get(params, function(err, data) {
            if (err) {
              console.log(err);
              return;
            } else console.log(data);

            var currentNumber = (data.Item && data.Item[YYYY_MM]) || 0;
            cb(null, currentNumber);
          });
        },

        //insert
        (currentNumber, cb) => {
          var newNumber = currentNumber + 1;
          var strFullTaxId = YYYY_MM + "-" + zeroPad(newNumber, 5);
          var newReceipt = Object.assign({}, receipt, {
            customer: _.omitBy(customer, _.isEmpty),
            fullTaxId: strFullTaxId,
            issue: getBusinessDate(   this.user.businessMode ),
            timestamp: moment().valueOf(),
            shopInfo: _.omitBy(shopInfo, _.isEmpty)
          });

          var params = {
            TableName: TABLE_NAME,
            Item: newReceipt
          };

          dynamoDbClient.put(params, function(err, data) {
            if (err) console.log(err);
            else console.log(data);

            cb(null, newNumber);
          });
        },

        //update current Number
        (newNumber, cb) => {
          var params = {
            TableName: TABLE_NAME_ACCOUNT_SHOP,
            Key: { customerId: this.customerId },
            UpdateExpression: "ADD #a :x ",
            ExpressionAttributeNames: { "#a": YYYY_MM },
            ExpressionAttributeValues: {
              ":x": 1
            }
          };

          dynamoDbClient.update(params, function(err, data) {
            if (err) {
              alert(err.message);
              return cb(err);
            }

            cb();
          });
        },

        //update current old Table
        cb => {
          var params = {
            TableName: TABLE_NAME_RECEIPT_IMAGE,
            Key: {
              customerId: receipt.customerId,
              "datetime-table-grandtotal": receipt["datetime-table-grandtotal"]
            },
            UpdateExpression: "set #a = :x, #b = :y",
            ExpressionAttributeNames: { "#a": "converted", "#b": "issue" },
            ExpressionAttributeValues: {
              ":x": true,
              ":y": getBusinessDate(simpleStore.getItem("businessMode"))
            }
          };

          dynamoDbClient.update(params, function(err, data) {
            if (err) {
              alert(err.message);
              return cb(err);
            }

            cb();
          });
        }
      ],
      err => {
        if (err) {
          return alert(err);
        }

        cbAll && cbAll();
      }
    );
  }

  edit(receipt, customer = {}, shopInfo, reason, cbAll) {
    var self = this;
    var YYYY_MM = moment(receipt.Date).format("YYYY-MM");

    var newObj = {
      ...receipt,
      customer: {
        ...customer
      },
      shopInfo: {
        ...shopInfo
      }
    };

    if (newObj.status == undefined) {
      newObj.status = [];
    }

    newObj.status.push({
      code: 300,
      name: "edit",
      remake: reason,
      timestamp: new Date().toDateString()
    });

    var params = {
      TableName: TABLE_V1_RECEIPTS_FULL_VAT,
      Item: newObj
    };
    dynamoDbClient.put(params, cbAll);
  }

  // look like no use
  update(receipt, customer = {}, reason, cbAll) {
    var self = this;
    var YYYY_MM = moment(receipt.Date).format("YYYY-MM");

    var params = {};
    params.TableName = TABLE_NAME;
    (params.Key = {
      customer: 2,
      "datetime-table-grandtotal": "2018-11-05T15:44:10#Job#1#845"
    }),
      (params.UpdateExpression = "SET #status = list_append(#status, :data)");
    (params.ExpressionAttributeNames = {
      "#status": "status"
    }),
      (params.ExpressionAttributeValues = {
        // ":data": {
        //   code: 300,
        //   name: "edit",
        //   remark: reason
        // }
        ":data": "3453"
      });
    dynamoDbClient.update(params, cbAll);
  }

  cancel(receipt, reason, cbAll) {
    var newObj = {
      ...receipt,
      isVoid: true,
      void : true
    };

    if (newObj.status == undefined) {
      newObj.status = [];
    }

    newObj.status.push({
      code: 900,
      name: "cancel",
      remake: reason,
      timestamp: new Date().toString()
    });

    var params = {
      TableName: TABLE_V1_RECEIPTS_FULL_VAT,
      Item: newObj
    };
    dynamoDbClient.put(params, cbAll);
  }

  download(itemOrArray, shopInfo, mode) {
    
     
    var array = [itemOrArray]
    if( _.isArray(itemOrArray) ){
      array = [...itemOrArray]
    }
    let dataReceipt = itemOrArray
    
    console.log(mock);
    blockScreen()
    var merged = mapperToPdfList(array, shopInfo, mode,dataReceipt) 
     
    return axios
      .post(URL_PDF_DATA, merged)
      .then(res => {
         
        this.downloadFile(res.data);
        unBlockScreen()
      })
      .catch(err => {
        unBlockScreen()

         
        if (err) {
          return "";
        }
      });
  }

  downloadFile(src) {
    var link = document.createElement("a");
    document.body.appendChild(link);
    link.href = src;
    link.download = "";
    link.click();
  }
}
