
const ID_DIV_ELEMENT = '#main-container'

export const blockScreen = () =>{
    window.$(ID_DIV_ELEMENT).addClass('be-loading-active')
}


export const unBlockScreen = () =>{
    window.$(ID_DIV_ELEMENT).removeClass('be-loading-active')
}