import React, { useState, useEffect } from "react";
import _ from "lodash";
import useFormInput from "./useFormInput";
import { dynamoDbClient } from "../aws";
import { connect } from "react-redux";

function customerInfoModal(props) {
  const name = useFormInput("");
  const address = useFormInput("");
  const branchId = useFormInput("");
  const taxId = useFormInput("");

  useEffect(() => {
    if (props.selected) {
      let selected = props.selected;
      name.setValue(selected.name);
      address.setValue(selected.address);
      taxId.setValue(selected.taxId);
      branchId.setValue(selected.branchId);
    } else {
      let selected = props.selected;
      name.setValue("");
      address.setValue("");
      taxId.setValue("");
      branchId.setValue("");
    }
  }, [props.selected]);

  function validate() {
    if (name === "" || taxId === "") {
      return true;
    }
    return null;
  }

  function getNullValue(text) {
    if (text !== "") {
      return text;
    } else {
      return null;
    }
  }

  function saveCustomer() {
    if (validate()) {
      alert("กรุณากรอกข้อมูลให้ครบถ้วน");
      return;
    }
    var params = {
      TableName: "V1_Tax_Customer_Address",
      Item: {
        customerId: props.customerId,
        name: name.value,
        taxId: taxId.value,
        address: getNullValue(address.value),
        branchId: getNullValue(branchId.value)
      }
    };

    dynamoDbClient.put(params, (err, data) => {
      if (err) {
        console.log(err);
        alert("ไม่สามารถบันทืึกข้อมูล");
      } else {
        alert("บันทึกข้อมูลสำเร็จ !");
        props.setSelectedData(null);
        window.$("#customerInfoModal").modal("hide");
        props.fetchCustomer();
      }
    });
  }

  return (
    <div
      class="modal"
      id="customerInfoModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="customerInfoModalLabel"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        role="document"
        // style={{ width: "30%" }}
      >
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="customerInfoModalLabel">
              {props.selected ? "Edit" : "Create"} Customer
            </h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <Row
              col1="Tax Id"
              col2={
                <input
                  className="form-control"
                  {...taxId}
                  disabled={props.selected}
                />
              }
            />
            <Row
              col1="Name"
              col2={<input className="form-control" {...name} />}
            />
            <Row
              col1="Branch Id"
              col2={<input className="form-control" {...branchId} />}
            />
            <Row
              col1="Address"
              col2={<textarea className="form-control" {...address} />}
            />
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              CLOSE
            </button>
            <button
              type="button"
              class="btn btn-space btn-primary"
              data-dismiss="modal"
              onClick={saveCustomer}
            >
              SUBMIT
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

const Row = ({ col1, col2 }) => {
  return (
    <div className="row m-0 mt-2 mb-2">
      <div className="col-md-3">{col1}</div>
      <div className="col-md-9">{col2}</div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    customerId: state.user.customerId
  };
};

export default connect(mapStateToProps)(customerInfoModal);
