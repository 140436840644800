import React, { Component } from "react";
import logo from "./logo.svg";
import "./App.css";
import NavBar from "./navBar";
import {
  BrowserRouter,
  Route,
  Link,
  Switch,
  withRouter
} from "react-router-dom";
import Home from "./pages/home";
import FullVat from "./pages/fullVat";
import Receipts from "./pages/receipts";
import CustomerInfo from "./pages/CustomerInfo";
import Detail from "./pages/viewDetail";
import { getCurrentUser, autoLogin, logout } from "./service/account";
import Login from "./pages/Login";
import { connect } from "react-redux";

class App extends Component {
  state = {
    flow: 0, // 0 == loding, 1 == need goto login page, 2 == complete,
    username: "",
    shopName: ""
  };

  handleLogout = () => {
    logout()
      .then(() => {
        window.location.reload();
      })
      .catch(res => {
        console.log(res.toString());
      });
  };

  componentDidMount() {
    this.props.dispatch(autoLogin());

    window.document.title = "ออกใบกำกับภาษีเต็มรูปแบบ";
  }

  render() {
    if (this.props.view.app === 0) {
      return <h1 class="text-center">Loading...</h1>;
    }
    if (this.props.view.app === 1) {
      return <Login loginSuccessCallback={this.loginSuccessCallback} />;
    }

    return (
      <BrowserRouter>
        <div className="be-wrapper be-nosidebar-left">
          <NavBar
            shopName={this.props.user.shopName}
            handleLogout={this.handleLogout}
            username={this.props.user.username}
          />

          {/* <div className="be-left-sidebar">
          <h1>SIDE LEFT</h1>
        </div> */}

          <div id="main-container" className="be-loading">
            <div className="be-content">
              <div className="main-content container-fluid">

                {/* <h3 style={{ background: "yellow" }}>ขณะนี้ระบบพบปัญหาการเข้าใช้งาน ทางทีมงานกำลังแก้ไข ขออภัยในความไม่สะดวกด้วยครับ</h3> */}

                <Switch>
                  <Route exact path="/" component={Home} />
                  <Route path="/Receipts" component={Receipts} />
                  <Route
                    path="/FullTaxInvoice/:isNewData"
                    component={FullVat}
                  />

                  <Route path="/FullTaxInvoice" component={FullVat} />
                  <Route path="/Detail" component={Detail} />
                  <Route path="/CustomerInfo" component={CustomerInfo} />
                </Switch>
              </div>
            </div>
            <div class="be-spinner">
              <svg
                width="40px"
                height="40px"
                viewBox="0 0 66 66"
                xmlns="http://-www.w3.org/2000/svg"
              >
                <circle
                  fill="none"
                  stroke-width="4"
                  stroke-linecap="round"
                  cx="33"
                  cy="33"
                  r="30"
                  class="circle"
                />
              </svg>
            </div>
          </div>
        </div>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    view: state.view,
    user: state.user
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
