import React from "react";
import FormValue from "./FormValue";
import InputMask from "react-input-mask";

export default function RenderCustomer(props) {
  
  const { handleOnChange, customer = {} } = props;
  const {
    createBill,
    updateBill,
    voidBill,
    isEdit,
    mode,
    handleEditClick
  } = props;
  // var isEditAndMode = isEdit;
  // if (mode === 0) {
  //   isEditAndMode = true;
  // }
  return (
    <div class="card card-border-color card-border-color-primary">
      <div class="card-header card-header-divider">
        ข้อมูลลูกค้า
        { isEdit   && <button
          onClick={props.findCustomer}
          class="btn btn-space btn-primary"
          style={{ float: "right" }}
          type="submit"
        >
          Search
        </button>}
        {mode === 1 && isEdit == false && (
          <button
            onClick={handleEditClick}
            class="btn btn-space btn-primary"
            style={{ float: "right" }}
            type="submit"
          >
            Edit
          </button>
        )}
      </div>
      <div class="card-body">
        {/* <form> */}
        <FormValue
          name="name"
          label="Company/Name"
          value={customer.name}
          textChange={handleOnChange}
          isEdit={isEdit}
        />

        {/* <FormValue
          name="branchId"
          label="รหัสสาขา"
          value={customer.branchId}
          textChange={handleOnChange}
          isEdit={isEditAndMode}
        /> */}

        {/* ใช้ mask แทน */}
        <div class="form-group">
          <label>
            รหัสสาขา{" "}
            <span
              class=""
              style={{ marginLeft: 16, color: "gray", fontStyle: "italic" }}
            >
              (สำนักงานใหญ่ใส่ 00000;
              <span style={{ marginLeft: 16 }}>หากไม่ใช้งานให้เว้นว่าง)</span>
            </span>
          </label>
          <InputMask
            mask="99999"
            maskChar={"_"}
            style={{ letterSpacing: 1 }}
            class="form-control"
            name={"branchId"}
            autocomplete="off"
            value={customer.branchId}
            onChange={evt => {
               ;
              handleOnChange("branchId", evt.target.value);
            }}
            readonly={isEdit ? null : "true"}
          />
          {/* <span class="card-subtitle">
            สำนักงานใหญ่ใส่ 0000;
            <span style={{ marginLeft: 16 }}>หากไม่ใช้งานให้เว้นว่าง</span>
          </span> */}
        </div>

        <FormValue
          name="taxId"
          label="เลขผู้เสียภาษี"
          value={customer.taxId}
          textChange={handleOnChange}
          isEdit={isEdit}
        />
        <FormValue
          name="address"
          label="ที่อยู่"
          value={customer.address}
          textChange={handleOnChange}
          isEdit={isEdit}
        />
        {isEdit && <div class="be-checkbox custom-control custom-checkbox">
          <input
            class="custom-control-input"
            type="checkbox"
            id="check1"
            checked={props.autoSaveCustomer}
            onClick={props.setAutoSaveCus}
          />
          <label class="custom-control-label" for="check1">
            Auto Save Company
          </label>
        </div>}
        <div class="row pt-3">
          {mode === 0 && (
            <div class="col-sm-12">
              <p class="text">
                <button
                  onClick={createBill}
                  class="btn btn-space btn-primary"
                  type="submit"
                >
                  Submit
                </button>
              </p>
            </div>
          )}

          {mode === 1 && isEdit == true && (
            <div class="col-sm-12">
              <p class="text">
                <button
                  onClick={updateBill}
                  class="btn btn-space btn-primary"
                  //type="submit"
                >
                  Update
                </button>
                <button
                  onClick={handleEditClick}
                  class="btn btn-space btn-secondary"
                >
                  Cancel
                </button>
              </p>
            </div>
          )}
        </div>
        {/* </form> */}
      </div>
    </div>
  );
}
