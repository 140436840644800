var store = {
  receipt: null,
  mode : 0,   // 0 =receipts , 1 = full vat,
  customerId : null,
  shopName : null,
  username : null,
  shopInfo : {},
  businessMode : 1,
};

export const setData = (data, mode) => {
  store.receipt = data;
  store.mode = mode;
};

export const getData = () => {
  return store;
};

export const setItem = (key, value) => {
  if(store[key] === undefined){
    throw 'cannot set empty key'
  }else{
    store[key]= value
  }
};

export const getItem = (key) => {
  return store[key]
};

// let store = {
//   customerId :  2,
//   businessMode : 1
// }

// export default store 