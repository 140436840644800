import { createStore, combineReducers } from 'redux'

import user from './reducer.user'
import shopInfo from './reducer.shopInfo'
import view from './reducer.view'
import holder from './reducer.holder'
import middlewaresApplied from './config.middleware'




const rootReducer = combineReducers({ 
    view ,
    user,
    shopInfo,
    holder
})



const store = createStore(rootReducer, middlewaresApplied)

export default store