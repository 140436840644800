import _ from "lodash";
import THBText from "thai-baht-text"; // for ES6
import { branchFormat, twoDecimal } from "./format";
import { computeVat } from ".";
export const mapperToPdfList = (receiptList, shopInfo, mode,dataReceipt) => {
  var customerLists = [];
  _.forEach(receiptList, r => {

    let computedVat = computeVat(r.GrandTotal, r.Vat, r.VatValue,r.VatMode)
    var changeShaped = {
      issue: r.issue,
      fullTaxId: r.fullTaxId,
      refId: "#" + r.OrderId,
      customer: {
        name: r.customer.name,
        branchId: branchFormat(r.customer.branchId),
        address: r.customer.address,
        taxId: r.customer.taxId
      },
      OrderItems: r.OrderItems || [],
      ServiceCharge: r.ServiceCharge || 0,
      Discount: r.Discount || 0,
      isShowProduct: r.isShowProduct || false,
      beforeVat:   (r.GrandTotal - Math.abs(r.Vat ||computedVat)),
      vat: Math.abs(r.Vat ||computedVat),
      vatText: "",
      grandTotal: r.GrandTotal,
      remark: THBText(r.GrandTotal)
    };
    customerLists.push(changeShaped);
  });

  var res = {
    shopInfo: {
      name: shopInfo.name,
      branchId: branchFormat(shopInfo.branchId),
      address: shopInfo.address.replace(/\n/g, " "),
      taxId: shopInfo.taxId,
      footer1: shopInfo.footer1 || "",

      //   tel: "02-0385600",
      logoUrl: shopInfo.logoUrl
    },
    mode: mode || 0,
    customerLists: customerLists,
    dataReceipt:{
      ...dataReceipt
    }
  };

  return res;
};

// demo

// export default {
//     shopInfo: {
//       name: "บริษัท ไนซ์ลูป จำกัด stman",
//       branch: "สำนักงานใหญ่",
//       address: "41/7 หมู่บ้านสวนฉัตร ซอย5 แขวงสามเสนนอก เขตห้วยขวาง กทม. 10310",
//       taxId: "0-1355-57000-06-1",
//       tel: "02-0385600",
//       logo: "shoplogo.png"
//     },
//     customerLists: [
//       {
//         customerId: "0000002",
//         createAt: "10/09/2018ca",
//         businessDate: "10/09/2018b",
//         isDeleted: false,
//         datetimeTableGrandTotal: "10/09/2018dtg",
//         refId: "rf0000001",
//         customer: {
//           name: "คุณเอสบี",
//           branch: "",
//           address: "13 ถ.555 แขวง สามเสนนอก เขตห้วยขวาง 10310",
//           id: "1-2345-78901-23-4"
//         },
//         items: [],
//         beforeVat: 15700,
//         vat: 200,
//         vatText: "7% inc.",
//         grandTotal: 15900,
//         remark: ""
//       },
//       {
//         customerId: "0000003",
//         createAt: "10/09/2018ca",
//         businessDate: "10/09/2018b",
//         isDeleted: false,
//         datetimeTableGrandTotal: "10/09/2018dtg",
//         refId: "rf0000002",
//         customer: {
//           name: "คุณยศ",
//           branch: "",
//           address: "77713 ถ.555 แขวง สามเสนนอก เขตห้วยขวาง 10310",
//           id: "9-2345-78901-23-9"
//         },
//         items: [],
//         beforeVat: 3800,
//         vat: 200,
//         vatText: "7% inc.",
//         grandTotal: 4000,
//         remark: ""
//       }
//     ]
//   };
