import { twoDecimal } from "./format";


//old no longer use
export const computeVat = (grandTotal, vatDatabase, vatValue, vatMode) => {
  if (vatDatabase != 0) {
    return vatDatabase;
  }

  switch (parseInt(vatMode)) {
    case 0:
      return 0;
      break;

    case 2:
      return twoDecimal(grandTotal * parseInt(vatValue) / (100 + parseInt(vatValue)))
    default:
      return 0;

      break;
  }

  return;
};

export const displayCurrency = (value = 0) => {
  //2 digits and comma
  return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

}

export const computeVat_V2 = (image) => {

 
  let isDivana  = image.CustomerId == '4970';

  var beforeVat = image.BeforeVatV2,
    vat = image.Vat,
    rounding = image.RoundingV2,
    grandTotal = image.GrandTotal;


  var vatMode = image.VatMode || 0;

  if (grandTotal == 0) {
    return {
      beforeVat: 0,
      vat: 0,
      grandTotal: 0,
      rounding: 0
    }
  }

  // divana - prodcut mode
  if (isDivana &&   (beforeVat  == grandTotal ||  beforeVat + rounding == grandTotal)  ) {

    //calculate vat from grandTotal
    var vatValue = 7;
    var vatAmount = (grandTotal * vatValue) / (100 + vatValue);
    var beforeVat = grandTotal - vatAmount;

    return {
      beforeVat: beforeVat,
      vat: vatAmount,
      grandTotal: grandTotal,
      rounding: 0
    }


  }


  // vat mode 2 - rounding after vat
  if (vatMode == 2 && vat == 0 && grandTotal != 0) {


    let vatAmount = beforeVat * 7 / 100;

    let rounding = grandTotal - beforeVat - vatAmount;


    return {
      beforeVat: beforeVat,
      vat: vatAmount,
      grandTotal: grandTotal,
      rounding: rounding
    }
  }


  // vat mode 2.0 (ogirinal)  - rounding before vat
  if (vatMode == 2) {

    return {
      beforeVat: beforeVat,
      vat: vat,
      grandTotal: grandTotal,
      rounding: rounding
    }

  }

  // vat mode 1
  if (vatMode == 1) {
    //calculate vat from grandTotal
    var vatValue = 7;
    var vatAmount = (grandTotal * vatValue) / (100 + vatValue);
    var beforeVat = grandTotal - vatAmount;
    return {
      beforeVat: beforeVat,
      vat: vatAmount,
      grandTotal: grandTotal,
      rounding: 0
    }
  }

  //vat mode = 0
  if (vatMode == 0) {

    return {
      beforeVat: beforeVat,
      vat: 0,
      grandTotal: grandTotal,
      rounding: 0
    }
  }

  //default
  return {
    beforeVat: beforeVat,
    vat: vat,
    grandTotal: grandTotal,
    rounding: rounding
  }

}


export const vatGenerate = (grandTotal, vatValue = 0) => {


  return {

    grandTotal,
    beforeVat: grandTotal * 100 / (100 + parseFloat(vatValue)),
    vat: grandTotal * parseFloat(vatValue) / (100 + parseFloat(vatValue)),

  }
};
