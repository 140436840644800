import React, { Component } from "react";
import FullTaxReceipts from "../../component/FullTaxReceipts.js";

export default class index extends Component {
  render() {

    return <FullTaxReceipts/>

  }
}
