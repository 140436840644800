
import monent from 'moment'


export const twoDecimal = (num3) =>
  parseFloat(Math.round(num3 * 100) / 100).toFixed(2);

export const zeroPad = function (num, places= 5) {
    var zero = places - num.toString().length + 1;
    return Array(+(zero > 0 && zero)).join("0") + num;
  }

  export const getBusinessDate = function (businessMode = 1 ) {
    var m =   businessMode == 1 ? monent().add(-7,'h') : monent()

    return m.format("YYYY-MM-DD")
  }


  //for export string :  pdf excel
  export const branchFormat = (branchId)=>{

    return branchId;
    var text = null;
    if(branchId == '0000'){
      text = " (สำนักงานใหญ่)"
    }else{
      text = " (สาขาที่ " + branchId+ ")";
    }
    if(branchId ==  undefined || branchId == null){
      text = null
    }
    return  text
  }

  //for display in list web
  export const branchFormatForWeb = (branchId)=>{
    var text = null;
    if(branchId == '0000'){
      text = "สำนักงานใหญ่"
    }else{
      text = branchId   
    }
    if(branchId ==  undefined || branchId == null){
      text = ''
    }
    return  text
  }