import React from "react";
import moment from "moment";
import EnhanceButton from "../../shared/EnhanceButton";
import { computeVat, computeVat_V2, displayCurrency, vatGenerate } from "../../utils";
import { twoDecimal } from "../../utils/format";
export default ({ receipt, i, onClickLine, onClickConvertLine, ...props }) => {
  let {
    ReceiptId,
    status,
    GrandTotal,
    Table,
    Date,
    Vat = 0,
    VatValue,
    VatMode,
    SubTotal,
    ItemDiscount,
    ServiceCharge,
    Discount,
    
  } = receipt;
   
  let { beforeVat, vat ,rounding, grandTotal  }   =  computeVat_V2(receipt)

   
  return (
    <tr>
      <td>{i + 1}.</td>
      <td>
        <span style={{}}>{moment(Date).format("HH:mm:ss")}</span>
        <span style={{ marginLeft: 8, color: "gray" }}>
          {moment(Date).format("DD/MM/YYYY")}
        </span>
      </td>
      <td>#{ReceiptId}</td>
      <td>{Table}</td>
      <td className="number">{ displayCurrency(beforeVat)}</td>
      <td className="number">{displayCurrency(vat)}</td>
      <td className="number">{displayCurrency(rounding)}</td>
      <td className="number">{displayCurrency(grandTotal)}</td>

      <td className="actions">

      <EnhanceButton
          handleOnClick={() => {
            onClickLine(receipt);
          }}
        >
          {" "}
          ออกใบกำกับภาษีเต็มรูปแบบ
        </EnhanceButton>
        
        {/* { !isVoid && }

        { isVoid && <span>VOIDED</span>} */}
      </td>
    </tr>
  );
   
};
