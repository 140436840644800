import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
var pathName = window.location.pathname;

switch (pathName) {
  // case '/admin':
  //     ReactDOM.render(<AdminPage />, document.getElementById("root"));
  //     break
  case "/":
    ReactDOM.render(
      <Provider store={store}>
        <App />
      </Provider>,
      document.getElementById("root")
    );
    break;
  default:
    window.location.href = "/";
    break;
}

//registerServiceWorker();


// let dat ={
//   storeUid : 2180,
//   date : {
//     "2019-09-09" : 10,
//     "2019-09-09" : 10,

//   }
  
// }


// let dat ={
//   ivnenotyUid : "1231",
//   storeUid : 2180,
//   "sjdgfjidsai" : {
//     type : 'add',
//     amount : 40
//   },
//   "23r2354" : {
//     type : 'add',
//     amount : 40
//   },  
//   "23r2354" : {
//     type : 'add',
//     amount : 40
//   }, 
// }