import axios from "axios";
import async from "async";
import { USER, VIEW } from '../redux/actions'
//app url
const LOGIN_URL = "https://app.niceloop.com/api/account/login";
const GET_CURRENT_USER = "https://app.niceloop.com/api/account/CurrentUser";
const LOGOUT_URL = "https://app.niceloop.com/api/account/logout";

//aws url
// const GET_SHOPNAME =
//   "https://bd8gco41te.execute-api.ap-southeast-1.amazonaws.com/dev/report/shopNameList";
// const GET_BUSINESS_MODE_URL =
//   "https://bd8gco41te.execute-api.ap-southeast-1.amazonaws.com/dev/report/getBusinessMode";

var ABB_URL = "https://urchin-app-olvz6.ondigitalocean.app";


const GET_SHOPNAME =
  ABB_URL + "/report/shopNameList";
const GET_BUSINESS_MODE_URL =
  ABB_URL + "/report/getBusinessMode";


export const login = (username, password) => {
  var obj = {
    Username: username,
    Password: password,
    Remember: true
  };
  return axios.post(LOGIN_URL, obj, { withCredentials: true });
};

export const getCurrentUser = () => {
  return axios.get(GET_CURRENT_USER, { withCredentials: true });
};

export const getShopName = cusotomerId => {
  //     body JSON :
  // [2,2240,3002,5428,3360]
  // // use CustomerId, Value
  // response :
  // [
  //     {
  //         "Id": 2,
  //         "Value": "Niceloop",
  //         "Category": "Parameter",
  //         "CustomerId": 2,
  //         "Name": "ShopName"
  //     },
  // ]
  debugger
  return axios.post(GET_SHOPNAME, [cusotomerId.toString()]);
};

export const logout = () => {
  window.localStorage.removeItem("username");
  window.localStorage.removeItem("password");
  return axios.post(LOGOUT_URL, {}, { withCredentials: true });
};

export const getBusinessMode = customerId => {
  return axios.post(GET_BUSINESS_MODE_URL, { customerId: customerId });
};

export const autoLogin = () => {
  return (dispatch, getState) => {
    var data = {
      customerId: null,
      shopName: null,
      username: null,
      businessMode: 1
    };
    async.waterfall(
      [
        //login
        cb => {
          let username = window.localStorage.getItem("username");
          let password = window.localStorage.getItem("password");
          login(username, password)
            .then((res) => {

              data.username = username;
              data.customerId = res.data.CustomerInfo.Id.toString();

              cb();
            })
            .catch(err => {
              cb(err);
            });
        },

        // get current user
        // cb => {
        //   getCurrentUser()
        //     .then(res => {
        //       data.customerId = res.data.CustomerId.toString();
        //       cb();
        //     })
        //     .catch(err => {
        //       cb(err);
        //     });
        // },

        //get shopName
        cb => {
          getShopName(data.customerId)
            .then(res => {
              data.shopName = res.data[0].Value;
              cb();
            })
            .catch(err => {

              cb(err);
            });
        }
      ],
      err => {
        if (err) {
          return dispatch({
            type: VIEW,
            payload: 1,
            key: 'app'
          })
        }
        dispatch({
          type: USER,
          payload: data
        })
        dispatch({
          type: VIEW,
          payload: 2,
          key: 'app'
        })
      }
    );
  };
};
