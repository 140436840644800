import { USER , SHOP_INFO, VIEW} from './actions'



// address สำหรับ ทางร้าน อาหาร  สำหรับ ออกบิล
export default (state = {}, action) => {
    switch (action.type) {
        case SHOP_INFO:
                return action.payload
        default:
            return state;
    }
};