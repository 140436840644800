import { USER, SHOP_INFO, VIEW } from "./actions";

let init = {
  app: 0,
  receipt: 0,
  loading: 0,
  tab : 0
};

export default (state = init, action) => {
  switch (action.type) {
    case VIEW:
      return { ...state, [action.key]: action.payload };
    default:
      return state;
  }
};
