import React, { Component } from "react";
import { dynamoDbClient, TABLE_ACCOUNTING_SHOP, s3 } from "../../aws";
import _ from "lodash";
import { SHOP_INFO } from "../../redux/actions";
import { connect } from "react-redux";
import InputMask from "react-input-mask";
class Home extends Component {
  state = {
    name: "",
    taxId: "",
    branchId: "",
    address: "",
    footer1: "",
    logoUrl: "",
    isEdit: false,
    file: null
  };
  fileInputRef = React.createRef();

  componentDidMount() {
    this.fetch();
  }

  fetch = () => {
    var self = this;
    var params = {
      TableName: TABLE_ACCOUNTING_SHOP,
      KeyConditionExpression: "customerId = :hkey",
      ExpressionAttributeValues: {
        ":hkey": this.props.user.customerId
      }
    };
    dynamoDbClient.query(params, (err, data) => {
      if (err) {
        return alert(err);
      }

      var res = data.Items[0] || {};
      self.props.fetchShopInfoSuccess(res.shopInfo);
    });
  };

  handleOnChange = (name, value) => {
    this.setState({ [name]: value });
  };

  save = () => {
    var params = {
      TableName: TABLE_ACCOUNTING_SHOP,
      Key: { customerId: this.props.user.customerId },
      UpdateExpression: "set #a = :x",
      ExpressionAttributeNames: { "#a": "shopInfo" },
      ExpressionAttributeValues: {
        ":x": _.omitBy(this.state, _.isEmpty)
      }
    };

    var self = this;
    dynamoDbClient.update(params, function(err, data) {
      if (err) console.log(err);
      else console.log(data);

      self.props.fetchShopInfoSuccess(self.state);
      self.setState({ isEdit: false });
    });
  };

  saveLogoUrl = locationS3 => {
    var params = {
      TableName: TABLE_ACCOUNTING_SHOP,
      Key: { customerId: this.props.user.customerId },
      UpdateExpression: "set #a.#b = :x",
      ExpressionAttributeNames: { "#a": "shopInfo", "#b": "logoUrl" },
      ExpressionAttributeValues: {
        ":x": locationS3
      }
    };

    var self = this;
    dynamoDbClient.update(params, function(err, data) {
      if (err) console.log(err);
      else console.log(data);

      self.setState({ logoUrl: locationS3, file: null });

      if (locationS3 == null) {
        window.location.reload();
      }
    });
  };

  handleIsEdit = () => {
    //true
    if (!this.state.isEdit) {
      this.setState({ ...this.props.shopInfo });
    }

    this.setState({ isEdit: !this.state.isEdit });
  };
  downloadExtention = () => {
    window.open('https://chrome.google.com/webstore/detail/niceloop-printer-extensio/ilboifjdaokpjbddccfeommkabcaimpe') 

  }

  renderLeftSide = () => {
    return (
      <div class="col-md-6">
        <h3>Application ออกใบกำกับภาษีเต็มรูปแบบ</h3>

        {/* <h3>กรุณากรอกข้อมูลบริษัทของทางร้านด้านขวามือก่อนการใช้งาน</h3> */}
        <p>ขั้นตอน การออกใบกำกับภาษีเต็มรูปแบบ</p>
        <ol>
          <li>กด EDIT จากแถบด้านขวา กรอกข้อมูลบริษัท แล้วบันทึก</li>
          <li>เลือก Tab ใบเสร็จรับเงิน</li>
          <li>เลือกวันที่ และ เลือกใบเสร็จที่ต้องกาาร</li>
          <li>ใส่ข้อมูลลูกค้าในแถบ ข้อมูลลูกค้า(ด้านขวามือ) แล้วกด Submit</li>
          <li>
            หลังจากเสร็จ เลือกบิลที่เพิ่งสร้างแล้วกด download
            เพื่อพิมพ์กำกับภาษีใบเต็มรูปแบบ
          </li>
        </ol>
        <h4 class="mt-4">
          ดาวน์โหลด เอ็กส์เทนชั่น ใช้สำหรับพิมพ์ใบกำกับภาษีทาง printer thermal
        </h4>
        <div style={{display:'flex',marginLeft:'25%'}}>
        <button
          style={{ float: "right" }}
          onClick={this.handleIsEdit}
          class="btn btn-space btn-secondary"
          onClick={this.downloadExtention}
        >
          <i class="icon icon-left mdi mdi-archive"></i>
          Download Extention
        </button>
        </div>
      </div>
    );
  };

  handleUpload = event => {
    var file = this.fileInputRef.current.files[0];
    var fileName = file.name;
    var customerId = this.props.user.customerId;

    var key = `customers/${customerId}/${fileName}`;

    var self = this;
    s3.upload(
      {
        Key: key,
        Bucket: "v1-tax",
        Body: file
      },
      function(err, data) {
        if (err) {
          return alert(
            "There was an error uploading your photo: ",
            err.message
          );
        }
        self.saveLogoUrl(data.Location);
        alert("Successfully uploaded photo.");
      }
    );
  };

  handleImageOnChange = evt => {
    var file = evt.target.files[0];
    console.log(file.name);
    this.setState({ file: file });
  };

  removeLogo = () => {
    var customerId = this.props.user.customerId;
    var key = this.props.shopInfo.logoUrl; //`customers/${customerId}/${fileName}`;
    var self = this;

    var params = {
      Bucket: "v1-tax",
      Key: key
    };
    s3.deleteObject(params, function(err, data) {
      if (err) {
        console.log(err, err.stack);
      }
      // an error occurred
      else {
        console.log(data); // successful response
        self.setState({ logoUrl: null });
        alert("Remove LOGO completed.");
        self.saveLogoUrl(null);
      }
    });
  };

  render() {
    let { isEdit } = this.state;
    return (
      <div className="row">
        {this.renderLeftSide()}
        <div className="col-md-6">
          <div className="card card-border-color card-border-color-primary">
            <div className="card-header card-header-divider">
              ข้อมูลบริษัท
              {isEdit == false && (
                <button
                  style={{ float: "right" }}
                  onClick={this.handleIsEdit}
                  class="btn btn-space btn-primary"
                >
                  Edit
                </button>
              )}
              <span className="card-subtitle" />
            </div>
            <div className="card-body">
              <div className="form-group row">
                <label
                  className="col-12 col-sm-3 col-form-label text-sm-right"
                  for="inputText3"
                >
                  บริษัท
                </label>
                <div className="col-12 col-sm-8 col-lg-6">
                  <input
                    className="form-control"
                    id="inputText3"
                    autocomplete="off"
                    type="text"
                    value={isEdit ? this.state.name : this.props.shopInfo.name}
                    onChange={evt =>
                      this.handleOnChange("name", evt.target.value)
                    }
                    readonly={isEdit ? null : "true"}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  className="col-12 col-sm-3 col-form-label text-sm-right"
                  for="inputText3"
                >
                  รหัสสาขา
                </label>
                <div className="col-12 col-sm-8 col-lg-6">
                  <InputMask
                    mask="99999"
                    maskChar={"_"}
                    style={{ letterSpacing: 1 }}
                    className="form-control"
                    id="inputText3"
                    autocomplete="off"
                    type="text"
                    value={
                      isEdit
                        ? this.state.branchId
                        : this.props.shopInfo.branchId
                    }
                    onChange={evt =>
                      this.handleOnChange("branchId", evt.target.value)
                    }
                    readonly={isEdit ? null : "true"}
                  />
                  <span
                    class="card-subtitle"
                    style={{
                      fontSize: "0.9em",
                      color: "gray",
                      fontStyle: "italic"
                    }}
                  >
                    สำนักงานใหญ่ใส่ 00000;
                    <span style={{ marginLeft: 16 }}>
                      หากไม่ใช้งานให้เว้นว่าง
                    </span>
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label
                  className="col-12 col-sm-3 col-form-label text-sm-right"
                  for="inputText3"
                >
                  Tax Id
                </label>
                <div className="col-12 col-sm-8 col-lg-6">
                  <input
                    className="form-control"
                    id="inputText3"
                    type="text"
                    autocomplete="off"
                    value={
                      isEdit ? this.state.taxId : this.props.shopInfo.taxId
                    }
                    onChange={evt =>
                      this.handleOnChange("taxId", evt.target.value)
                    }
                    readonly={isEdit ? null : "true"}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  className="col-12 col-sm-3 col-form-label text-sm-right"
                  for="inputTextarea3"
                >
                  ที่อยู่
                </label>
                <div className="col-12 col-sm-8 col-lg-6">
                  <textarea
                    autocomplete="off"
                    style={{ height: 100 }}
                    className="form-control"
                    id="inputTextarea3"
                    value={
                      isEdit ? this.state.address : this.props.shopInfo.address
                    }
                    onChange={evt =>
                      this.handleOnChange("address", evt.target.value)
                    }
                    readonly={isEdit ? null : "true"}
                  />
                </div>
              </div>

              {/* LOGO */}
              <div className="form-group row">
                <label
                  className="col-12 col-sm-3 col-form-label text-sm-right"
                  for="inputText3"
                >
                  Logo
                </label>
                <div className="col-12 col-sm-8 col-lg-9">
                  {this.props.shopInfo.logoUrl && (
                    <img
                      height={80}
                      src={
                        this.state.logoUrl
                          ? this.state.logoUrl
                          : this.props.shopInfo.logoUrl
                      }
                    />
                  )}
                  {this.props.shopInfo.logoUrl && (
                    <button
                      onClick={this.removeLogo}
                      style={{ verticalAlign: "bottom", marginLeft: 36 }}
                    >
                      remove logo
                    </button>
                  )}
                  <div style={{ marginTop: 8 }}>
                    {
                      <input
                        onChange={this.handleImageOnChange}
                        type="file"
                        accept="image/jpeg"
                        ref={this.fileInputRef}
                      />
                    }
                    {this.state.file && (
                      <button onClick={this.handleUpload}>
                        {" "}
                        Confirm Upload
                      </button>
                    )}
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <label
                  className="col-12 col-sm-3 col-form-label text-sm-right"
                  for="inputTextarea3"
                >
                  Custom Header1
                </label>
                <div className="col-12 col-sm-8 col-lg-6">
                  <input
                    className="form-control"
                    id="inputTextarea3"
                    autocomplete="off"
                    value={
                      isEdit ? this.state.footer1 : this.props.shopInfo.footer1
                    }
                    onChange={evt =>
                      this.handleOnChange("footer1", evt.target.value)
                    }
                    readonly={isEdit ? null : "true"}
                  />

                  <span
                    class="card-subtitle"
                    style={{
                      fontSize: "0.9em",
                      color: "gray",
                      fontStyle: "italic"
                    }}
                  >
                    ข้อมูลเพิ่มเติมเช่นเลขเครื่อง POS หรืออื่นๆ,
                    ถ้าไม่ใช้เว้นว่าง
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label
                  className="col-12 col-sm-3 col-form-label text-sm-right"
                  for="inputText3"
                />
                <div className="col-12 col-sm-8 col-lg-6">
                  {isEdit == false && (
                    <button
                      onClick={this.handleIsEdit}
                      class="btn btn-space btn-primary"
                    >
                      Edit
                    </button>
                  )}

                  {isEdit == true && (
                    <button
                      onClick={this.save}
                      style={{ width: 100 }}
                      class="btn btn-space btn-primary"
                    >
                      Save
                    </button>
                  )}

                  {isEdit == true && (
                    <button
                      onClick={this.handleIsEdit}
                      class="btn btn-space btn-secondary"
                    >
                      Cancel
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
    shopInfo: state.shopInfo
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch,
    fetchShopInfoSuccess(shopInfo) {
      dispatch({
        type: SHOP_INFO,
        payload: { ...shopInfo }
      });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
