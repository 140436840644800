import moment from "moment";
import Paper from "niceloop-paper";
import _ from "lodash";
import formatNum from "format-num";
import { computeVat_V2, displayCurrency, vatGenerate } from "../../utils";

class PrintThermal {
  receipt = null;
  dispatch = null;
  printCmdResult = null;
  printType = "PRINT_CUSTOM_V2";

  constructor(dispatch, receipt, mode) {
    this.dispatch = dispatch;
    this.receipt = receipt;
    this.mode = mode;

  }



  build() {

    var receipt = this.receipt;
    const { shopInfo } = receipt;
    let mode = this.mode;

    var Order = {};
    Order.UniqueId = this.getRandomInt(99999999999);
    Order.Printer = [0];
    Order.Title1 = "";
    Order.Title2 = "";
    Order.CustomHeadersArrays = [
      this.createHeader1(receipt),
      this.createHeader2(receipt, mode),
      this.createHeader3(receipt),
      this.createHeader4(receipt),
      this.createHeader5(receipt),
      this.createFooter1(receipt)
    ];

    Order.CustomFootersArrays = [];
    this.Order = Order;

    this.Order = Order;
    return this;
  }

  createHeader1(receipt) {
    const { shopInfo } = receipt;
    let paper = new Paper();


    this.wordsPrint(paper, shopInfo.name, 43)

    paper.lineFeed();
    let _addressOneLine = shopInfo.address.replaceAll('\n', ' ')
    this.wordsPrint(paper, _addressOneLine, 43)


    if (shopInfo.branchId == '0000' || shopInfo.branchId == '00000') {
      paper.textLine(`  (สำนักงานใหญ่)`);

    } else {
      paper.lineFeed();
      paper.textLine(`สาขาที่ออกใบกำกับภาษี ${shopInfo.branchId || "-"}`);

    }
    paper.lineFeed();
    paper.textLine(`เลขผู้เสียภาษีอากร ${shopInfo.taxId || "-"}`);

    return paper.gen();
  }
  createHeader2(receipt, mode) {
    let paper = new Paper();
    const { shopInfo } = receipt;
    paper.lineFeed();
    paper.title("ใบเสร็จรับเงิน/ใบกำกับภาษี");
    paper.title(`(${mode})`);
    paper.lineFeed();
    paper.keyValue("RECEIPT / TAX INVOICE", "");
    paper.keyValue(`    เลขที่  ${receipt.fullTaxId}`, "");
    paper.keyValue(
      `    วันที่  ${moment(receipt.timestamp).format("DD/MM/YYYY")}`,
      ""
    );
    paper.drawLine();

    return paper.gen();
  }
  createHeader3(receipt) {
    let paper = new Paper();
    paper.keyValue(`สำหรับลูกค้า / ชื่อลูกค้า`, "");
    paper.lineFeed();


    this.wordsPrint(paper, this.receipt.customer.name, 43)

    // if (this.receipt.customer.name.length > 30) {
    //   let chunkName = chunkString(this.receipt.customer.name, 30);
    //   _.forEach(chunkName, (text, idx) => {
    //     if (idx === 0) {
    //       paper.textLine(`${text}`);
    //     } else {
    //       paper.textLine(`${text}`);
    //     }
    //   });
    // } else {
    //   paper.textLine(`${this.receipt.customer.name}`);
    // }

    if (this.receipt.customer.branchId) {
      let branchText =
        this.receipt.customer.branchId == "00000" || this.receipt.customer.branchId == "0000"
          ? "  (สำนักงานใหญ่)"
          : "  (สาขาที่ " + this.receipt.customer.branchId + ")";
      paper.textLine(branchText);
    }

    paper.lineFeed();
    this.wordsPrint(paper, this.receipt.customer.address, 43)

    // if (this.receipt.customer.address.length > 37) {
    //   let checkAdd = chunkString(this.receipt.customer.address, 37);
    //   _.forEach(checkAdd, (text, idx) => {
    //     paper.textLine(`${text}`);
    //   });
    // } else {
    //   paper.textLine(`${this.receipt.customer.address}`);
    // }

    paper.lineFeed();
    paper.textLine(`เลขผู้เสียภาษีอากร ${this.receipt.customer.taxId}`);
    paper.lineFeed();
    paper.drawLine();

    return paper.gen();
  }
  createHeader4(receipt) {
    let paper = new Paper();
    let GrandTotal = formatNum(receipt.GrandTotal.toFixed(2), {
      minFraction: 2
    });

    paper.keyValue("1 ค่าอาหารและเครื่องดื่ม", displayCurrency(receipt.GrandTotal));

    paper.drawLine();
    // paper.title(shopInfo.name || '-');

    return paper.gen();
  }
  createHeader5(receipt) {
    let paper = new Paper();

    // let  { beforeVat, vat }  = vatGenerate(receipt.GrandTotal, receipt.VatValue)
    //   beforeVat =
    //   formatNum(beforeVat, {
    //     minFraction: 2
    //   }) || "-";
    // let GrandTotal = formatNum(receipt.GrandTotal.toFixed(2), {
    //   minFraction: 2
    // });
    // vat = formatNum(vat, {
    //   minFraction: 2
    // });


    let { beforeVat, vat, rounding, grandTotal } = computeVat_V2(receipt)


    paper.keyValue("รวมมูลค่าสินค้าก่อนภาษีมูลค่าเพิ่ม", displayCurrency(beforeVat));
    paper.keyValue("ภาษีมูลค่าเพิ่ม 7%", displayCurrency(vat));
    rounding != 0 && paper.keyValue("(ปัดเศษ) " + displayCurrency(rounding), " ");
    paper.keyValue("รวมเงินทั้งสิ้น", displayCurrency(grandTotal));
    paper.drawLine();


    return paper.gen();
  }
  createFooter1() {
    let paper = new Paper();

    paper.lineFeed();
    paper.lineFeed();
    paper.lineFeed();
    paper.keyValue("", "ผู้รับเงิน_____________");
    paper.lineFeed();
    paper.lineFeed();
    // paper.comment("ใบเสร็จรับเงินนี้จะสมบูรณ์ต่อเมื่อมีลายเซนต์ชื่อผู้รับเงิน");
    paper.comment("ใบเสร็จนี้จะสมบูรณ์เมื่อมีลายเซนต์ชื่อผู้รับเงิน");

    return paper.gen();
  }

  genSummaryHeader() {
    var paper = new Paper();
    let {
      therapist,
      receiptId,
      meta = {},
      member,
      refId,
      orderId,
      isMultiple,
      localTime
    } = this.receipt;
    // paper.keyValue(`Order No : ${jobUid}`);
    paper.keyValue(`Reciept ID : ${receiptId}`);
    paper.keyValue(`Ref ID :  #${refId || orderId}`);
    paper.keyValue(`Date : ${moment(localTime).format("DD/MM/YYYY HH:mm")}`);
    therapist && paper.keyValue(`Therapist : ${therapist.name || "-"}`);
    member && paper.keyValue(`Guest : ${member.name || "-"}`);

    // if (isMultiple) {
    //   paper.keyValue(`*** Multiple Therapist`);
    // } else {
    //   paper.keyValue(
    //     `Time : ${moment(meta.startDate).format("HH:mm")} - ${moment(
    //       meta.endDate
    //     ).format("HH:mm")}`
    //   );
    // }

    paper.lineFeed();

    return paper.gen();
  }

  wordsPrint(paper, paragraph = "", maxLength = 43) {
    let words = paragraph.split(' ')
    let countRunningText = 0
    let text = ""
    _.forEach(words, (w, i) => {
      countRunningText += (w.length + 1)
      if (countRunningText > maxLength) {
        paper.textLine(text)
        countRunningText = 0;
        text = w + ' '
      } else {
        text = text + w + ' '

      }
    })
    text.length >= 1 && paper.textLine(text)

  }

  print() {
    this.dispatch({
      type: "PRINT",
      meta: {
        printType: this.printType,
        type: "PRINT"
      },
      payload: this.Order
    });
  }

  getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }
}

export default PrintThermal;



const chunkString = (str, len) => {
  var _size = Math.ceil(str.length / len),
    _ret = new Array(_size),
    _offset;
  for (var _i = 0; _i < _size; _i++) {
    _offset = _i * len;
    _ret[_i] = str.substring(_offset, _offset + len);
  }
  return _ret;
};
