import React, { useState } from "react";
import _ from "lodash";
import moment from "moment";
import { computeVat as computeVatGlobal, computeVat_V2, displayCurrency, vatGenerate } from "../../utils";
const styleBlock = {
  display: "flex",
  justifyContent: "space-between"
};

function LeftSide(props) {
   ;
  const {
    issue,
    fullTaxId,
    Date,
    GrandTotal,
    OrderId,
    Table,
    Vat,
    isShowProduct,
    OrderItems,
    ServiceCharge,
    Discount,
    VatMode,
    VatValue,
    SubTotal,
    ItemDiscount,
    PaymentType,
    multiplePayment = [],
    Cashier = ""
  } = props.receipt || {};
  const { mode, setIsShowProduct, user, isDivana, image } = props;
  let beforVat = SubTotal + -ItemDiscount + ServiceCharge + -Discount;
   ;
  if (Vat < 0) {
    beforVat = beforVat + Vat;
  }
  let round = (GrandTotal - (beforVat + Vat)).toFixed(2);
  // Declare a new state variable, which we'll call "count"

  return (
    <div class="card card-border-color card-border-color-primary">
      {mode == 1 && [
        <div class="card-header">ข้อมูลใบกำกับภาษีเต็มรูปแบบ</div>,
        <div class="card-body widget-chart-container">
          <div
            class="widget-counter-group widget-counter-group-center"
            style={styleBlock}
          >
            <TitleBlock name="เลขที่ใบกำกับภาษี" value={fullTaxId} />
            <TitleBlock name="วันที่ออกใบ" value={issue} />
          </div>
        </div>
      ]}

      <div class="card-header">
        <div className="d-flex justify-content-between">
          <div>ข้อมูลใบเสร็จ</div>
          <div>
            {isDivana(user) &&
              (isShowProduct || Vat === 0) &&
              (mode === 0 || isShowProduct) && (
                <div
                  class="custom-control custom-checkbox"
                  onClick={() => mode === 0 && setIsShowProduct(!isShowProduct)}
                >
                  <input
                    class="custom-control-input"
                    type="checkbox"
                    checked={isShowProduct}
                    disabled={mode !== 0}
                  />
                  <label class="custom-control-label" for="check3">
                    Is Product ?
                  </label>
                </div>
              )}
          </div>
        </div>
      </div>
      <div class="card-body widget-chart-container">
        <div
          class="widget-counter-group widget-counter-group-center"
          style={styleBlock}
        >
          <TitleBlock
            name="Date"
            value={moment(Date).format("HH:mm DD/MMM/YYYY")}
          />
          <TitleBlock name="Order Id" value={"#" + OrderId} />
          <TitleBlock name="Table" value={Table} />
          <TitleBlock name="GrandTotal" value={GrandTotal} />
          {/* <TitleBlock name="Cashier" value={Cashier || "-"} /> */}
        </div>
      </div>

      <Items
        GrandTotal={GrandTotal}
        Vat={Vat}
        user={props.user}
        isShowProduct={isShowProduct}
        mode={mode}
        isDivana={isDivana}
        OrderItems={OrderItems}
        ServiceCharge={ServiceCharge}
        Discount={Discount}
        VatMode={VatMode}
        VatValue={VatValue}
        beforVat={beforVat}
        round={round}
        multiplePayment={multiplePayment}
        PaymentType={PaymentType}
        image={image}
        // paymentType={paymentType}
      />
    </div>
  );
}

function Items({
  GrandTotal,
  Vat,
  VatValue,
  VatMode,
  user,
  isShowProduct,
  mode,
  isDivana,
  OrderItems,
  ServiceCharge,
  Discount,
  beforVat,
  round,
  multiplePayment = [],
  PaymentType = "",
  image
}) {
   ;
 

  let { beforeVat, vat ,rounding, grandTotal  }   =  computeVat_V2(image)
   
 
  // return (<div class="card-header">Items</div> )
  return [
    
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th>Qty</th>
          <th>Name</th>
          <th>Price</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        {isShowProduct ? (
          _.map(OrderItems, (item, idx) => {
            return (
              <tr>
                <td>{item.quantity}</td>
                <td>{item.name}</td>
                <td>{item.discountedPrice / item.quantity}</td>
                <td>{item.discountedPrice}</td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td>1</td>

            <td>ค่าอาหารและเครื่องดื่ม</td>
            <td>{beforVat}</td>
            <td>{beforVat}</td>
          </tr>
        )}

        {isShowProduct && [
          ServiceCharge > 0 && (
            <tr>
              <td />
              <td />
              <td>
                <b>Service</b>
              </td>
              <td>
                <b>{ServiceCharge}</b>
              </td>
            </tr>
          ),
          Discount > 0 && (
            <tr>
              <td />
              <td />
              <td>
                <b>Discount</b>
              </td>
              <td>
                <b>{Discount}</b>
              </td>
            </tr>
          )
        ]}

        <tr>
          <td />

          <td />
          <td>
            <b>Before</b>
          </td>
          <td>
            <b>{displayCurrency(beforeVat)  }</b>
          </td>
        </tr>
        <tr>
          <td />

          <td />
          <td>
            <b>Vat</b>
          </td>
          <td>
            <b>{displayCurrency(vat)  }</b>
          </td>
        </tr>
        <tr>
          <td />

          <td />
          <td>
            {" "}
            <b>Rounding</b>
          </td>
          <td>
            <b>{displayCurrency(rounding)}</b>
          </td>
        </tr>
        <tr>
          <td />

          <td />
          <td>
            <b>GrandTotal</b>
          </td>
          <td>
            <b>{  displayCurrency(grandTotal )}</b>
          </td>
        </tr>
        <tr>
          <td />

          <td />
          <td>
            <b>Payment Type</b>
          </td>
          <td>
            {multiplePayment.length > 1 &&
              _.map(multiplePayment, type => {
                return (
                  <b>
                    {`${type.name} : ${type.amount}`}
                    <br />
                  </b>
                );
              })}
            {multiplePayment.length < 1 && <b>{PaymentType || "-"}</b>}
          </td>
        </tr>
      </tbody>
    </table>
  ];
}

function TitleBlock({ value, name }) {
  return (
    <div class="counter counter-big">
      <div class="value">{value}</div>
      <div class="desc">{name}</div>
    </div>
  );
}

export default LeftSide;
