import { USER, SHOP_INFO, VIEW, HOLDER } from "./actions";

let init = {
  receipt : null,
};

export default (state = init, action) => {
  switch (action.type) {
    case HOLDER:
      return { ...state, [action.key]: action.payload };
    default:
      return state;
  }
};
