
import React from 'react';

export default function FormValue({ name, label, value, textChange, placeHolder, isEdit }) {
    return (
      <div class="form-group">
        <label>{label}</label>
        <input
          class="form-control"
          name={name}
          value={value}
          placeholder={placeHolder}
          autocomplete="off"     
               onChange={evt => {
             ;
            textChange(name, evt.target.value);
          }}
          readonly={isEdit ? null : "true"}

        />
      </div>
    );
  }
  