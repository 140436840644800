import React, { Component } from "react";
import LeftSide from "./LeftSide";
import fullVatService from "../../service/fullVatService";
import * as simpleStore from "../../simpleStore";
import { withRouter } from "react-router-dom";
import FormValue from "./FormValue";
import { connect } from "react-redux";
import RenderCustomer from "./RenderCustomer";
import { dynamoDbClient } from "../../aws";
import _ from "lodash";

class Detail extends Component {
  state = {
    customer: {
      name: "",
      address: "",
      branchId: "",
      taxId: ""
    },
    isEdit: false,
    receipt: {},
    isShowProduct: false,
    autoSaveCustomer: true
  };

  componentDidMount() {
    // if (data.receipt && data.receipt.customer) {
    //   this.setState({ customer: data.receipt.customer });
    // }

    if (this.props.mode === 0) {
      this.setState({ isEdit: true });
    }

    if (this.props.mode === 1) {
      this.setState({ isEdit: false });
    }

    this.setReceipt(this.props.receipt);
  }

  setIsShowProduct = isShowProduct => {
    this.setState({
      receipt: { ...this.state.receipt, isShowProduct }
    });
  };
  setReceipt = receipt => {
    this.setState({
      receipt
      // receipt: { ...receipt, isShowProduct: true }
    });
  };

  componentWillUpdate() {
    console.log("next props :===>");

    console.log(this.props);
  }

  findCustomer = () => {
    const { user } = this.props;
    let res = window.prompt("กรุณากรอกเลข Tax Id");
    if (!res) return;
    var self = this;
    var params = {
      TableName: "V1_Tax_Customer_Address",
      Key: {
        customerId: user.customerId,
        taxId: res
      }
    };
    dynamoDbClient.get(params, (err, data) => {
      if (err) {
        alert("ไม่พบ Tax Id นี้");
        console.log(err);
        return;
      }
      let item = data.Item;
      if (item) {
        console.log(item);
        self.setState({
          customer: {
            name: item.name || "",
            address: item.address || "",
            branchId: item.branchId || "",
            taxId: item.taxId || ""
          }
        });
      } else {
        alert("ไม่พบ Tax Id นี้");
        return;
      }
    });
  };

  saveCustomer = () => {
    var { customer } = this.state;
    let { user } = this.props;

    var params = {
      TableName: "V1_Tax_Customer_Address",
      Item: {
        customerId: user.customerId,
        name: customer.name,
        taxId: customer.taxId,
        address: getNullValue(customer.address),
        branchId: getNullValue(customer.branchId)
      }
    };

    dynamoDbClient.put(params, (err, data) => {
      if (err) {
        console.log(err);
      }
    });
  };
  createBill = receipt => {
    var { customer, autoSaveCustomer, receipt } = this.state;
    var { shopInfo, user, mode } = this.props;

    //AUTO SAVE CUATOMER
    if (autoSaveCustomer && customer.taxId) {
      this.saveCustomer();
    }

    let newReciept = _.cloneDeep(receipt);

    //START COMPUTE PRODUCT VAT
    if (
      isDivana(user) &&
      mode === 0 &&
      (receipt.Vat === 0 || !receipt.Vat) &&
      receipt.isShowProduct //CHECK CUSTOMER ID, CHECK MODE 0,CHECK VAT 0
    ) {
      newReciept.Vat = parseFloat(((receipt.GrandTotal * 7) / 107).toFixed(2));
      newReciept.Vatable = parseFloat(
        (receipt.GrandTotal - (receipt.GrandTotal * 7) / 107).toFixed(2)
      );
      newReciept.isDivana = true;
    }
    //END COMPUTE PRODUCT VAT

    new fullVatService(user.customerId, user).convert(
      newReciept,
      customer,
      shopInfo,
      () => {
        window.alert("Create Fulll Tax Invoice Completed");

        // this.handleBack2();
        this.props.history.push("/FullTaxInvoice/newdata");
      }
    );
  };

  updateBill = receipt => {
    var { customer, autoSaveCustomer, receipt } = this.state;
    var { user, shopInfo } = this.props;
    //AUTO SAVE CUATOMER
    if (autoSaveCustomer && customer.taxId) {
      this.saveCustomer();
    }
    new fullVatService(user.customerId, user).edit(
      receipt,
      customer,
      shopInfo,
      "EDITED INVOICE",
      (err, data) => {
        if (err) {
          throw err;
        }
        window.alert("Edit Completed");

        // this.setState({ isFullVat: true });
        this.handleBack2();
      }
    );
  };

  downloadPdf = mode => {
    //util make pdf
    var { customer, shopInfo } = this.props;
    const { receipt } = this.state;

    var service = new fullVatService();
    service.download(receipt, shopInfo, mode);
  };

  removeFullInvoice = () => {
    var res = window.prompt("Reason Void this Invoice");

    // xxxService.put(receipt)
    // .then()
    // .catch()

    var { customer, autoSaveCustomer, receipt } = this.state;
    var { shopInfo, user, mode } = this.props;


    res && new fullVatService(user.customerId, user).cancel(
      receipt,
      res || "-",
      (err, data) => {
        if (err) {
          throw err;
        }
        window.alert("Edit Completed");

        // this.setState({ isFullVat: true });
        this.handleBack2();
      }
    );
  };

  handleBack1 = () => {
    this.props.history.push("/Receipts");
  };

  handleBack2 = () => {
    this.props.history.push("/FullTaxInvoice");
  };
  _handleOnChange = (name, value) => {
    var c = this.state.customer;
    var newCustomer = { ...c, [name]: value };
    this.setState({ customer: newCustomer });
  };

  handleEditClick = () => {
    this.setState({ isEdit: !this.state.isEdit });
    if (this.props.mode == 1) {
      this.setState({ customer: { ...this.state.receipt.customer } });
    }
  };


  render() {
    const { autoSaveCustomer } = this.state;
    return (
      <div class="row ">
        <div class="col-lg-12" style={{ marginBottom: 20 }}>
          <div class="row">
            <div class="col-lg-6">
              {this.props.mode === 0 && (
                <button
                  class="btn btn-rounded btn-space btn-secondary"
                  onClick={this.handleBack1}
                >
                  <i class="icon icon-left  " />
                  Back
                </button>
              )}

              {this.props.mode === 1 && (
                <button
                  class="btn btn-rounded btn-space btn-secondary"
                  onClick={this.handleBack2}
                >
                  <i class="icon icon-left  " />
                  Back
                </button>
              )}
            </div>
            <div class="col-lg-6 text-right">
              {this.props.mode === 1 && <button
                  class="btn btn-secondary   btn-rounded btn-space dropdown-toggle"
                  type="button"
                  style={{ marginTop: -4 }}
                  onClick={this.removeFullInvoice}
                >
                  <i class="mdi mdi-delete mr-1"></i>

                  Void
                </button> }
            
              {this.props.mode === 1 && [

                <div class="btn-group btn-space ">
                  <button
                    class="btn btn-secondary   btn-rounded btn-space dropdown-toggle"
                    type="button"
                    data-toggle="dropdown"
                    aria-expanded="true"
                  >
                    Download Full Invoice
                    <span class="icon-dropdown mdi mdi-chevron-down" />
                  </button>
                  <div
                    class="dropdown-menu "
                    role="menu"
                    x-placement="top-start"
                  >
                    <a
                      onClick={() => this.downloadPdf(0)}
                      class="dropdown-item"
                      href="#"
                    >
                      ต้นฉบับ
                    </a>
                    <a
                      onClick={() => this.downloadPdf(1)}
                      class="dropdown-item"
                      href="#"
                    >
                      สำเนา
                    </a>

                    <div class="dropdown-divider" />
                    <a
                      onClick={() => this.downloadPdf(2)}
                      class="dropdown-item"
                      href="#"
                    >
                      ต้นฉบับ + สำเนา
                    </a>
                  </div>
                </div>
              ]}{" "}
            </div>
          </div>
        </div>

        <div class="col-lg-8">
          {this.props.mode == 1 && (
            <ReceiptMode text={"ใบกำกับภาษีเต็มรูปแบบ"} mode={1} />
          )}
          {this.props.mode === 0 && (
            <ReceiptMode text={"ใบกำกับภาษีอย่างย่อ"} mode={0} />
          )}

          {/* <OwnerForm/> */}

          <LeftSide
            receipt={this.state.receipt}
            image = {this.state.receipt}
            mode={this.props.mode}
            user={this.props.user}
            setIsShowProduct={this.setIsShowProduct}
            isDivana={isDivana}
          />

          {/* <OwnerFormView {...this.state.shopInfo}/> */}
        </div>

        <div class="col-lg-4">
          <RenderCustomer
            customer={
              this.state.isEdit
                ? this.state.customer
                : this.state.receipt.customer
            }
            handleOnChange={this._handleOnChange}
            createBill={this.createBill}
            updateBill={this.updateBill}
            voidBill={this.voidBill}
            handleEditClick={this.handleEditClick}
            isEdit={this.state.isEdit}
            mode={this.props.mode}
            autoSaveCustomer={autoSaveCustomer}
            setAutoSaveCus={() =>
              this.setState({ autoSaveCustomer: !autoSaveCustomer })
            }
            findCustomer={this.findCustomer}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
    receipt: state.holder.receipt,
    mode: state.view.receipt,
    shopInfo: state.shopInfo
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Detail));

function ReceiptMode({ text, mode }) {
  const classText =
    mode == 1
      ? "alert alert-success bg-success text-white"
      : "alert alert-warning bg-warning text-white";
  return (
    <div
      class={classText}
      role="alert"
      style={{ fontSize: 20, textAlign: "center" }}
    >
      {text}
    </div>
  );
}

function getNullValue(text) {
  if (text !== "") {
    return text;
  } else {
    return null;
  }
}

const isDivana = user => {
  let customerId = user.customerId;
  if (customerId === "4970") return true;
  if (customerId === "5527") return true;
  if (customerId === "5578") return true;
  if (customerId === "5669") return true;
  if (customerId === "5915") return true;

  if (customerId === "2") return true;

  return false;
};
